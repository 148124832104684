import React, {useState, useContext, useEffect, useRef} from 'react';
import chimera from '../../chimera';
import FormFieldMicro from '../FormFieldMicro';
import AddressDisplay from '../CustomerPage/AddressDisplay';
import PocDisplay from '../CustomerPage/PocDisplay';
import BannerContext, {BannerLog} from '../BannerLogContext';
import Modal, {choiceCancel, choiceDelete} from '../Modal';
import LoadingSpinner from '../LoadingSpinner';
import { NewCustomerBody } from '../NewCustomer';
import ModalContext from '../ModalContext';
import MiniNotes from '../MiniNotes';
import EmailModal from '../EmailModal';
import Autocomplete from '../Autocomplete';
import FileUpload from '../FileUpload';
import ExpandableDropdown from '../ExpandableDropdown';
import UserContext from '../../UserContext';
import CreateSyncroTicketModal from './CreateSyncroTicketModal';

const DEFAULT_PRODUCT = {
    name: '',
    qty: 0,
    price: 0,
    dids: 0
}

const ProductArea = (props) => {
    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        props.setModified(true);
        const name = event.target.name;
        const value = event.target.type !== "checkbox" ? event.target.value : event.target.checked;

        let newOrder = JSON.parse(JSON.stringify(props.workingOrder));

        if(name === "name") {
            const selectedItem = props.tsPairs.find((pair) => pair.item === value);
            if(selectedItem) {
                newOrder.products[props.itemIndex].name = selectedItem.item;
            }
        }
        else if(event.target.type === "number") {
            chimera.setAttr(newOrder, name, parseFloat(value));
        }
        else {
            chimera.setAttr(newOrder, name, value);
        }

        props.setWorkingOrder(newOrder);
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    return(
        <div className={`section-outline${props.itemIndex > 0 ? ' mt-2' : ''}`}>
            <div className="row">
                <div className="col">
                    <FormFieldMicro
                        type="select"
                        name={`products[${props.itemIndex}].name`}
                        label="Name"
                        value={props.workingOrder.products[props.itemIndex].name}
                        handleChange={handleChange}
                        disabled={props.locked}
                        options={props.tsPairs.filter((pair) => props.workingOrder.enableFax ? true : !pair.item.toLowerCase().includes('fax')).map((pair) => { return {id: pair.item, value: pair.item}})}
                        fit
                    />
                </div>
                <div className="col">
                    <FormFieldMicro
                        type="number"
                        name={`products[${props.itemIndex}].qty`}
                        label="Qty"
                        value={props.workingOrder.products[props.itemIndex].qty}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={props.locked}
                        fit
                    />
                </div>
                <div className="col">
                    <FormFieldMicro
                        type="number"
                        name={`products[${props.itemIndex}].dids`}
                        label="DIDs"
                        value={props.workingOrder.products[props.itemIndex].dids}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={props.locked}
                        fit
                    />
                </div>
                <div className="col">
                    <FormFieldMicro
                        type="text"
                        name={`products[${props.itemIndex}].price`}
                        label="Unit Price"
                        value={props.tsPairs.find(tspair => tspair.item === props.workingOrder.products[props.itemIndex].name) ? chimera.dollarStr(props.tsPairs.find(tspair => tspair.item === props.workingOrder.products[props.itemIndex].name).price) : '(Unknown)'}
                        disabled={true}
                        fit
                    />
                </div>
                {props.showDelete ? <div className="col d-flex"><button className="btn btn-sm btn-danger w-fit h-fit my-auto mx-auto" onClick={(e) => {e.preventDefault(); props.removeItemAtIndex(props.itemIndex)}}><i className="fas fa-times"/></button></div>:null}
            </div>
        </div>
    )
}

const HardwareArea = (props) => {
    const modaling = useContext(ModalContext);

    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        props.setModified(true);
        const name = event.target.name;
        const value = event.target.type !== "checkbox" ? event.target.value : event.target.checked;

        let newOrder = JSON.parse(JSON.stringify(props.workingOrder));

        if(name === "name") {
            const selectedItem = props.tsPairs.find((pair) => pair.item === value);
            if(selectedItem) {
                newOrder.products[props.itemIndex].name = selectedItem.item;
            }
        }
        else if(event.target.type === "number") {
            chimera.setAttr(newOrder, name, parseFloat(value));
        }
        else {
            chimera.setAttr(newOrder, name, value);
        }

        props.setWorkingOrder(newOrder);
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    return(
        <div className={`section-outline${props.itemIndex > 0 ? ' mt-2' : ''}`}>
            <div className="row">
                <FormFieldMicro
                    type="component"
                    name={`hardware[${props.itemIndex}].name`}
                    label="Name"
                    fit
                >
                    <ExpandableDropdown 
                        listName="VOIP_ORDER_PHONE_MODELS"
                        htmlName={`hardware[${props.itemIndex}].name`}
                        value={props.workingOrder.hardware[props.itemIndex].name}
                        handleChange={handleChange}
                        disabled={props.locked}
                        modalContext={modaling}
                    />
                </FormFieldMicro>
                <FormFieldMicro
                    type="number"
                    name={`hardware[${props.itemIndex}].qty`}
                    label="Qty"
                    value={props.workingOrder.hardware[props.itemIndex].qty}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled={props.locked}
                    fit
                />
                {props.showDelete ? <>&nbsp;<button className="btn btn-sm btn-danger w-fit h-fit my-auto" onClick={(e) => {e.preventDefault(); props.removeItemAtIndex(props.itemIndex)}}><i className="fas fa-times"/></button></>:null}
            </div>
        </div>
    )
}

/**
 *  @param props.type the enum string (see VoipOrder schema) 
 *  @param props.workingOrder the object being edited. If left undefined or null, POST requests will be used on save instead of PUT.
 * */
const VoipOrderFormBody = props => {
    const [createButtonDisabled, setCreateButtonDisabled] = useState((props.workingOrder.customerRef ? true : false));
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);
    const userContext = useContext(UserContext);
    const firstRender = useRef(true);

    const getCustomer = () => {
        const customer = props.customers.find((customer) => customer.accountNumber === props.workingOrder.customerRef);
        return customer;
    }

    const getLocationIndex = () => {
        const customer = getCustomer();
        if(!customer) return 0;
        for(let i = 0; i < customer.locations.length; i++) {
            if(customer.locations[i].nickname === props.workingOrder.customerLocationNickname) return i;
        }
        return 0;
    }

    useEffect(() => {
        if(firstRender.current) {
            firstRender.current = false;
            return;
        }
        else if(props.type !== "New Customer") {
            const customer = getCustomer();
            if(customer) {
                const newOrder = JSON.parse(JSON.stringify(props.workingOrder));

                if(props.workingOrder.customerLocationNickname === "ADD_NEW_LOCATION") {
                    newOrder.serviceAddress = chimera.DEFAULT_ADDRESS;
                    newOrder.businessPhone = '';
                    newOrder.poc = chimera.DEFAULT_POC;
                    newOrder.billingPoc = customer.billingPocs[0];
                    newOrder.billingAddress = customer.billingAddress;
                }
                else {
                    const locationIndex = getLocationIndex();

                    newOrder.serviceAddress = customer.locations[locationIndex].serviceAddress;
                    newOrder.businessPhone = customer.locations[locationIndex].businessPhone;
                    newOrder.poc = customer.locations[locationIndex].poc;
                    newOrder.billingPoc = customer.billingPocs[0];
                    newOrder.billingAddress = customer.billingAddress;
                }

                props.setWorkingOrder(newOrder);
                props.setModified(true);
            }
        }
    }, [props.workingOrder.customerLocationNickname, props.workingOrder.customerRef]);

    const checkLocked = () => {
        if(userContext.permissions.admin.write && !props.isSaving) return false;
        if(props.isSaving) return true;
        switch(props.type) {
            case 'New Customer':
                return props.workingOrder.customerRef !== "" || props.workingOrder.status === "Cancelled" || props.workingOrder.status === "Completed";
            default:
                return props.workingOrder.status === "Cancelled" || props.workingOrder.status === "Completed";
        }
    }

    const locked = checkLocked();

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }
    
    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        props.setModified(true);
        const name = event.target.name;
        const value = event.target.type !== "checkbox" ? event.target.value : event.target.checked;
        let newWorkingOrder = JSON.parse(JSON.stringify(props.workingOrder));
        
        if(name === "onboardingPerson") {
            const selectedUser = props.users.find((user) => user.email === value);
            if(selectedUser) {
                newWorkingOrder.onboardingPerson = selectedUser;
            }
        }
        else if(event.target.type === "number") {
            chimera.setAttr(newWorkingOrder, name, parseFloat(value));
        }
        else if(name.split('.')[name.split('.').length-1].toLowerCase().includes("phone")) {
            chimera.setAttr(newWorkingOrder, name, value.replace(/\D/g, ''));
        }
        else if(name !== "onboardingPerson") {
            chimera.setAttr(newWorkingOrder, name, value);
        }

        props.setWorkingOrder(newWorkingOrder);
    }

    const handleSaveError = (err) => {
        console.error(err);
        if(err.details && err.details.name === "ValidationError") {
            for(const key in err.details.errors) {
                banners.addBanner('danger', err.details.errors[key].message, 'Validation Error');
            }
        }
        else if(err.name !== 'AbortError') {
            banners.addBanner('danger', 'Failed to save order', 'Error');
        }
    }

    const handleSave = (event, order) => {
        if(event) event.preventDefault();
        const resolve = (savedOrder) => {
            if(props.saveMode === 'PUT') {
                banners.addBanner('info', `Changes saved successfully.`, 'Saved');
            }
            else { // saveMode === 'POST'
                banners.addBanner('info', `The Voip Order${savedOrder.customerName ? ` for ${savedOrder.customerName}` : ""} was created successfully.`, 'Success');
                props.setSaveMode('PUT');
            }
            props.setWorkingOrder(savedOrder);
            props.setSavedOrder(savedOrder);
            /** TODO: HANDLE ALERTS
            if(alertsContext.internetOrderIsPendingRenewal(savedOrder._id) && savedOrder.renewalApproved) {
                alertsContext.removeInternetOrder(savedOrder._id);
            }
            else if(alertsContext.internetOrderIsAfterCheckInDate(savedOrder._id)) {
                alertsContext.removeInternetOrder(savedOrder._id);
            }
            */
        }
        props.handleSave(resolve, handleSaveError, order);
    }

    const trimCustomer = (cust, includeKeyFunc) => {
        let ret = {};
        const excludeKeys = [
            "_id", "__v", "createdAt", "createdBy",
            "modifiedBy", "updatedAt", "accountNumber"
        ];
        for(const key in cust) {
            if(!excludeKeys.includes(key) && (includeKeyFunc === undefined || includeKeyFunc(key))) {
                ret[key] = cust[key];
            }
        }
        return ret;
    }

    const handlePushToCustomer = (event) => {
        event.preventDefault();
        const controller = new AbortController();
        const signal = controller.signal;
        const dismiss = (event) => {
            event.preventDefault();
            controller.abort();
            modaling.setModal(null);
        }

        let customer;
        let locationIndex;
        if(props.savedOrder.type === "New Customer") {
            locationIndex = 0;
            customer = new chimera.CommercialCustomer();
        }
        else {
            const selectedCustomer = getCustomer();
            if(!selectedCustomer) {
                banners.addBanner('danger', 'The selected customer could not be determined. Please notify the site administrator about this issue.', 'Customer Error');
                return;
            }
            locationIndex = -1;
            for(let i = 0; i < selectedCustomer.locations.length; i++) {
                if(selectedCustomer.locations[i].nickname === props.savedOrder.customerLocationNickname) {
                    locationIndex = i;
                    break;
                }
            }

            if(locationIndex === -1 && props.savedOrder.customerLocationNickname !== "ADD_NEW_LOCATION") {
                banners.addBanner('danger', 'The selected location could not be found on the associated customer. Please notify the site administrator about this issue.', 'Location Error');
                return;
            }
            else if(props.savedOrder.customerLocationNickname === "ADD_NEW_LOCATION" && locationIndex === -1) {
                customer = new chimera.CommercialCustomer(selectedCustomer);
                locationIndex = customer.locations.length;
                customer.locations = [...customer.locations, chimera.DEFAULT_LOCATION];
                customer.locations[locationIndex].nickname = props.savedOrder.newLocationNickname;
                customer.locations[locationIndex].shippingAddress = props.savedOrder.serviceAddress;
            }

            if(!customer) customer = new chimera.CommercialCustomer(selectedCustomer, locationIndex);
            console.log(customer);
        }

        customer.locations[locationIndex].serviceTypes.voip = props.savedOrder.type !== "Disconnect" ? true : false;
        customer.displayName = props.savedOrder.customerName;
        customer.businessName = props.savedOrder.customerName;
        customer.locations[locationIndex].serviceAddress = props.savedOrder.serviceAddress;
        customer.locations[locationIndex].businessPhone = props.savedOrder.businessPhone;
        customer.billingPhone = props.savedOrder.billingPoc.phone;
        customer.locations[locationIndex].poc = props.savedOrder.poc;
        customer.billingAddress = props.savedOrder.billingAddress;
        customer.billingPocs = [props.savedOrder.billingPoc];
        customer.locations[locationIndex].technical.emailSentDate = props.savedOrder.emailSentDate;

        if(!props.savedOrder.customerRef) {
            const createdCustomerCallback = (createdCustomer) => {
                chimera.callAPI(undefined, `/api/msporders/${props.workingOrder._id}`, 'PUT', {customerRef: createdCustomer.accountNumber})
                .then(newOrder => {
                    props.setSavedOrder(newOrder);
                    props.setWorkingOrder(newOrder);
                    setCreateButtonDisabled(true);
                    modaling.backtrack();
                })
                .catch(err => {
                    console.error(err);
                    modaling.backtrack();
                    banners.addBanner('danger', 'Failed to save customer reference to this Order. Please alert the website administrator so that the customer reference can be manually linked.', 'Error');
                })
            }
    
            const modal = <Modal choices={[]} dismiss={dismiss}>
                <BannerLog>
                    <NewCustomerBody customer={customer} controller={controller} signal={signal} createdCustomerCallback={createdCustomerCallback} hideIPTable/>
                </BannerLog>
            </Modal>
            modaling.setModal(modal);
        }
        else {
            const loadingModal = <Modal choices={[]} dismiss={dismiss}>
                <BannerLog>
                    <LoadingSpinner size={50} label={`Pushing to ${customer.displayName}...`}/>
                </BannerLog>
            </Modal>
            modaling.setModal(loadingModal);

            chimera.callAPI(signal, `/api/customers/${customer._id}`, 'PUT', trimCustomer(customer._rawCustomerData))
            .then(_ => {
                modaling.backtrack();
                banners.addBanner('info', `Saved changes to location ${customer.locations[locationIndex].nickname} for ${customer.displayName} in Chimera.`, 'Success');
            })
            .catch(err => {
                console.error(err);
                modaling.backtrack();
                if(err.details.name === "ValidationError") {
                    for(const key in err.details.errors) {
                        const error = err.details.errors[key];
                        if(error.path === "billingPhone") {
                            banners.addBanner('danger', `The Billing POC's Phone Number is required because it is used as the location's Billing Phone, a required field.`, 'Validation Error');
                        }
                        else {
                            banners.addBanner('danger', error.message, 'Validation Error');
                        }
                    }
                }
                else {
                    banners.addBanner('danger', 'Failed to update the customer.', 'Error');
                }
            })
        }
    }

    const setNotes = (newNotes) => {
        props.setModified(true);
        const newWorkingOrder = JSON.parse(JSON.stringify(props.workingOrder));
        newWorkingOrder.notes = newNotes;
        props.setWorkingOrder(newWorkingOrder);
    }

    const openEmailModal = (event) => {
        event.preventDefault();
        const callback = () => {
            const newWorkingOrder = JSON.parse(JSON.stringify(props.workingOrder));
            newWorkingOrder.emailSentDate = new Date();

            const resolve = (savedOrder) => {
                props.setWorkingOrder(savedOrder);
                props.setSavedOrder(savedOrder);
                props.setSaveMode('PUT');
            }

            const reject = (err) => {
                console.error(err);
            }

            props.handleSave(resolve, reject, newWorkingOrder);
        }
        /** NOTE: CPEs were removed because they aren't part of the schema. Should they be added? */
        modaling.setModal(<EmailModal callback={callback} to={props.workingOrder.poc.email} modalContext={modaling} backtrack subject={props.type === "Disconnect" ? 'Your Business VoIP Disconnect is Complete' : `Your Business VoIP Onboarding is Complete`} text={
            props.type === "Disconnect" ? 
            `${props.workingOrder.customerName},\n\nYour Business VoIP disconnect is now complete.\n
If you have any questions or issues please reach out and we will be happy to help you!\n
Business Hours:
Monday-Friday
7am-4:30pm

Tech Support:
support@gocbit.com
(509) 578-5424 option 2

Billing:
accounting@gocbit.com
(509) 578-5424 option 3`
            :
            `${props.workingOrder.customerName},\n\nYour Business VoIP is now complete.\n
If you have any questions or issues please reach out and we will be happy to help you!\n
Business Hours:
Monday-Friday
7am-4:30pm

Tech Support:
support@gocbit.com
(509) 578-5424 option 2

Billing:
accounting@gocbit.com
(509) 578-5424 option 3`
        }/>);
    }

    const suggestionChosenCallback = (suggestion) => {
        const customer = props.customers.find((customer) => customer.displayName === suggestion);
        if(customer) {
            const newOrder = JSON.parse(JSON.stringify(props.workingOrder));
            newOrder.customerName = customer.displayName;
            newOrder.customerRef = customer.accountNumber;
            newOrder.customerLocationNickname = customer.locations[0].nickname;
            props.setWorkingOrder(newOrder);
            props.setModified(true);
        }
        else {
            banners.addBanner('danger', 'Something went wrong with selecting the customer.', 'Error');
        }
    }

    const disableCreateButton = () => {
        if(props.isSaving) return true;

        if(props.type === "New Customer") {
            return createButtonDisabled || !props.saveDisabled
        }
        else {
            return !props.saveDisabled;
        }
    }

    const createButtonLabel = () => {
        switch(props.type) {
            case 'New Customer':
                return 'Create Customer';
            case 'Disconnect':
                return 'Disconnect Location';
            default:
                return 'Update Customer';
        }
    }

    const locationOptions = () => {
        const customer = getCustomer();
        if(!customer) return [];
        let options = customer.locations.map((location) => { return {id: location.nickname, value: location.nickname}});
        if(props.type === "Modify Service") {
            options = [...options, {id: 'ADD_NEW_LOCATION', value: '+ Add Location...'}];
        }
        return options;
    }

    const addProduct = (e) => {
        e.preventDefault();
        const newOrder = JSON.parse(JSON.stringify(props.workingOrder));
        newOrder.products = [...props.workingOrder.products, DEFAULT_PRODUCT];
        props.setWorkingOrder(newOrder);
    }

    const removeProductAtIndex = (index) => {
        const newOrder = JSON.parse(JSON.stringify(props.workingOrder));
        newOrder.products = props.workingOrder.products.filter((_, i) => i !== index);
        props.setWorkingOrder(newOrder);
    }

    const addHardware = (e) => {
        e.preventDefault();
        const newOrder = JSON.parse(JSON.stringify(props.workingOrder));
        newOrder.hardware = [...props.workingOrder.hardware, DEFAULT_PRODUCT];
        props.setWorkingOrder(newOrder);
    }

    const removeHardwareAtIndex = (index) => {
        const newOrder = JSON.parse(JSON.stringify(props.workingOrder));
        newOrder.hardware = props.workingOrder.hardware.filter((_, i) => i !== index);
        props.setWorkingOrder(newOrder);
    }

    const addAttachment = (file, contents) => {
        console.log({file, contents});

        chimera.callAPI(undefined, '/api/file', 'POST', {
            filename: file.name,
            type: file.type,
            size: file.size,
            content: contents,
            encoding: 'base64'
        })
        .then(savedFile => {
            const newOrder = JSON.parse(JSON.stringify(props.workingOrder));
            let newAttachment = {
                filename: savedFile.filename,
                type: savedFile.type,
                size: savedFile.size,
                id: savedFile._id
            }
            if(newOrder.attachments === undefined) {
                newOrder.attachments = [newAttachment]
            }
            else {
                newOrder.attachments.push(newAttachment);
            }
            handleSave({preventDefault: () => {}}, newOrder);
        })
        .catch(err => {
            console.error(err);
            banners.addBanner('danger', 'Failed to upload attachment', 'Error');
        })
        .finally(() => {
            modaling.backtrack();
        })
    }

    const openFileUploadModal = (event) => {
        event.preventDefault();
        const choices = [
            choiceCancel({modalContext: modaling, backtrack: true}),
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <FileUpload callback={addAttachment}/>
        </Modal>
        modaling.setModal(modal);
    }

    const sizeAbbreviation = (sizeInBytes) => {
        if(sizeInBytes >= 1000000) {
            return `${(sizeInBytes / 1000000).toFixed(1)} MB`;
        }
        else if(sizeInBytes >= 1000) {
            return `${(sizeInBytes / 1000).toFixed(1)} KB`;
        }
        else {
            return `${(sizeInBytes).toFixed(1)} B`;
        }
    }

    const downloadAttachment = (attachment) => {
        const modal = <Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.backtrack();}}>
            <LoadingSpinner size={75} label="Downloading..."/>
        </Modal>
        modaling.setModal(modal);

        chimera.callAPI(undefined, `/api/file/${attachment.id}`)
        .then(file => {
            fetch(`data:${file.type};base64,${file.content}`)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = file.filename;
                link.click();
            })
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to download file', 'Error');
            })
        })
        .catch(err => {
            console.error(err);
            banners.addBanner('danger', 'Failed to download file', 'Error');
        })
        .finally(() => {
            modaling.backtrack();
        })
    }

    const deleteAttachment = (attachment) => {

        const choices = [
            choiceCancel({modalContext: modaling, backtrack: true}),
            choiceDelete(
                {modalContext: modaling},
                () => {
                    const modal = <Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.backtrack(); modaling.backtrack();}}>
                        <LoadingSpinner size={75} label="Deleting..."/>
                    </Modal>
                    modaling.setModal(modal);
            
                    chimera.callAPI(undefined, `/api/file/${attachment.id}`, 'DELETE')
                    .then(_ => {
                        const newOrder = JSON.parse(JSON.stringify(props.workingOrder));
                        newOrder.attachments = newOrder.attachments.filter(a => a.id !== attachment.id);
                        handleSave({preventDefault: () => {}}, newOrder);
                    })
                    .catch(err => {
                        console.error(err);
                        banners.addBanner('danger', 'Failed to delete file', 'Error');
                    })
                    .finally(() => {
                        modaling.backtrack();
                        modaling.backtrack();
                    })
                },
                {noConfirm: true}
            )
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Are you sure?</h3>
            <p>Are you sure you want to delete the attached file? This operation cannot be undone. Filename: {attachment.filename}</p>
        </Modal>
        modaling.setModal(modal);
    }

    const createSyncroTicket = (event) => {
        event.preventDefault();

        const callback = () => {
            const newOrder = JSON.parse(JSON.stringify(props.workingOrder));
            newOrder.ticketCreatedDate = (new Date()).toISOString();
            handleSave({preventDefault: () => {}}, newOrder);
        }

        modaling.setModal(<CreateSyncroTicketModal orderClass="voip" orderId={props.savedOrder._id} modalContext={modaling} bannerContext={banners} callback={callback} backtrack/>)
    }

    const calculateTotal = () => {
        if(props.tsPairs === null) return 0;
        let total = 0;
        for(const product of props.workingOrder.products) {
            for(const tsPair of props.tsPairs) {
                if(product.name === tsPair.item) {
                    total += product.qty * tsPair.price;
                }
            }
        }
        return total;
    }

    return(
        <div className="row">
            <div className="col-9 border-end">
                <h3>VoIP Order: {props.type}</h3>
                <h4>Customer Details</h4>
                <div className="row row-cols-1 row-cols-md-4 g-4">
                    {props.type === "New Customer" ? 
                    <FormFieldMicro
                        type="text"
                        name="customerName"
                        label="Customer Name"
                        value={props.workingOrder.customerName}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={locked}
                    />
                    :
                    <>
                    <div className="col">
                        <Autocomplete
                            label="Customer Name"
                            value={props.workingOrder.customerName}
                            suggestions={props.customers.map(customer => customer.displayName)}
                            suggestionChosenCallback={suggestionChosenCallback}
                            strictMode
                            disabled={locked}
                        />
                    </div>
                    <div className="col">
                        <FormFieldMicro
                            type="select"
                            name="customerLocationNickname"
                            label="Location"
                            value={props.workingOrder.customerLocationNickname}
                            handleChange={handleChange}
                            options={locationOptions()}
                            excludeNoneSelected
                            disabled={locked}
                            fullWidth
                        />
                    </div>
                    {props.workingOrder.customerLocationNickname === 'ADD_NEW_LOCATION' ? 
                    <div className="col">
                        <FormFieldMicro
                            type="text"
                            name="newLocationNickname"
                            label="New Location Name"
                            value={props.workingOrder.newLocationNickname}
                            handleChange={handleChange}
                            onBlur={trimOnBlur}
                            disabled={locked}
                        />
                    </div>
                    :null}
                    </>
                    }
                    {props.type !== "Disconnect" ? 
                    <>
                    <FormFieldMicro
                        type="text"
                        name="businessPhone"
                        label="Business Phone"
                        value={props.workingOrder.businessPhone}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={locked}
                    />
                    <div className="col w-50">
                        <FormFieldMicro
                            type="component"
                            name="serviceAddress"
                            label="Service Address"
                            disabled={locked}
                        >
                            <AddressDisplay addr={props.workingOrder.serviceAddress} basePath="serviceAddress" baseValue={props.workingOrder} onChange={handleChange} isEditing onBlur={trimOnBlur} disabled={locked}/>
                        </FormFieldMicro>
                    </div>
                    <div className="col w-50">
                        <FormFieldMicro
                            type="component"
                            name="poc"
                            label="Technical POC"
                            disabled={locked}
                        >
                            <PocDisplay poc={props.workingOrder.poc} basePath="poc" baseValue={props.workingOrder} onChange={handleChange} isEditing onBlur={trimOnBlur} disabled={locked}/>
                        </FormFieldMicro>
                    </div>
                    <div className="col w-50">
                    <FormFieldMicro
                        type="component"
                        name="billingAddress"
                        label="Billing Address"
                        disabled={locked}
                    >
                        <AddressDisplay addr={props.workingOrder.billingAddress} basePath="billingAddress" baseValue={props.workingOrder} onChange={handleChange} isEditing onBlur={trimOnBlur} disabled={locked}/>
                    </FormFieldMicro>
                    </div>
                    <div className="col w-50">
                    <FormFieldMicro
                        type="component"
                        name="billingPoc"
                        label="Billing POC"
                        disabled={locked}
                    >
                        <PocDisplay poc={props.workingOrder.billingPoc} basePath="billingPoc" baseValue={props.workingOrder} onChange={handleChange} isEditing onBlur={trimOnBlur} disabled={locked}/>
                    </FormFieldMicro>
                    </div>
                    <FormFieldMicro
                        type="select"
                        name="contractTerm"
                        label="Contract Term"
                        value={props.workingOrder.contractTerm}
                        handleChange={handleChange}
                        options={[
                            {id: "Monthly", value: "Monthly"},
                            {id: "12 Months", value: "12 Months"},
                        ]}
                        disabled={locked}
                    />
                    <FormFieldMicro
                        type="date"
                        name="billStartDate"
                        label="Bill Start Date"
                        value={props.workingOrder.billStartDate}
                        handleChange={handleChange}
                        disabled={locked}
                    />
                    </>
                :
                    <FormFieldMicro
                        type="date"
                        name="billStartDate"
                        label="Disconnect Date"
                        value={props.workingOrder.billStartDate}
                        handleChange={handleChange}
                        disabled={locked}
                    />
                }
                </div>
                <hr/>
                {props.type !== "Disconnect" ? 
                <>
                <h4>Order Details</h4>
                <div className="row row-cols-1 row-cols-md-4 g-4">
                    <FormFieldMicro
                        type="checkbox"
                        name="enableSms"
                        label="Enable SMS?"
                        value={props.workingOrder.enableSms}
                        handleChange={handleChange}
                        disabled={props.isSaving || locked}
                        checkLabel=""
                    />
                    <FormFieldMicro
                        type="checkbox"
                        name="enableFax"
                        label="Enable Fax?"
                        value={props.workingOrder.enableFax}
                        handleChange={handleChange}
                        disabled={props.isSaving || locked}
                        checkLabel=""
                    />
                    <FormFieldMicro
                        type="checkbox"
                        name="enableInternational"
                        label="Enable International?"
                        value={props.workingOrder.enableInternational}
                        handleChange={handleChange}
                        disabled={props.isSaving || locked}
                        checkLabel=""
                    />
                    {/** "US and Canada", "Australia + <US and Canada>", "Cuba + <US and Canada>", "International Light", "Permit All" */}
                    {props.workingOrder.enableInternational ? 
                    <FormFieldMicro
                        type="select"
                        name="international"
                        label="International"
                        value={props.workingOrder.international}
                        handleChange={handleChange}
                        options={[
                            {id: "US and Canada", value: "US and Canada"},
                            {id: "Australia + <US and Canada>", value: "Australia + <US and Canada>"},
                            {id: "Cuba + <US and Canada>", value: "Cuba + <US and Canada>"},
                            {id: "International Light", value: "International Light"},
                            {id: "Permit All", value: "Permit All"},
                        ]}
                        disabled={locked || locked}
                    />
                    :null}
                </div>
                <div className="row section-outline mx-1">
                    <div className="col">
                        <div className="row">
                            <div className="col-6">
                                <h5 className="text-start">Service</h5>
                                <h6 className="text-start"><strong>Total:&nbsp;{chimera.dollarStr(calculateTotal())}</strong></h6>
                            </div>
                            <div className="col-6">
                                <FormFieldMicro
                                    type="number"
                                    name="credit"
                                    label="Credit"
                                    value={props.workingOrder.credit}
                                    handleChange={handleChange}
                                    onBlur={trimOnBlur}
                                    disabled={locked}
                                    min={0}
                                    step={0.01}
                                    classes={['float-end']}
                                />
                            </div>
                        </div>
                        {props.workingOrder.products.map((_, i) => <div key={i} className="row">
                            <div className="col">
                                <ProductArea {...props} itemIndex={i} removeItemAtIndex={removeProductAtIndex} showDelete={!locked} locked={locked}/>
                            </div>
                        </div>)}
                        <div className="row mt-2">
                            <div className="col d-flex flex-column">
                                <button className="btn btn-success w-fit" onClick={addProduct} disabled={locked}>
                                    <i className="fas fa-plus"/>&nbsp;Add Service
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row section-outline my-2 mx-1">
                    <div className="col">
                        <h5 className="text-start">Products</h5>
                        {props.workingOrder.hardware.map((_, i) => <div key={i} className="row">
                            <div className="col">
                                <HardwareArea {...props} itemIndex={i} removeItemAtIndex={removeHardwareAtIndex} showDelete={!locked} locked={locked}/>
                            </div>
                        </div>)}
                        <div className="row mt-2">
                            <div className="col d-flex flex-column">
                                <button className="btn btn-success w-fit" onClick={addHardware} disabled={locked}>
                                    <i className="fas fa-plus"/>&nbsp;Add Product
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-outline">
                    <div className="row">
                        <div className="col">
                            <h5 className="text-start">Phone Number Status</h5>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-3">
                        <FormFieldMicro
                            type="select"
                            name="portStatuses.phoneNumber.retailStatus"
                            label="Retail Status"
                            value={props.workingOrder.portStatuses.phoneNumber.retailStatus}
                            handleChange={handleChange}
                            options={[
                                {id: "Quote Requested", value: "Quote Requested"},
                                {id: "Quote Sent", value: "Quote Sent"},
                                {id: "LOA Requested", value: "LOA Requested"},
                                {id: "LOA Sent", value: "LOA Sent"},
                                {id: "LOA Completed", value: "LOA Completed"},
                            ]}
                            disabled={locked}
                        />
                        <FormFieldMicro
                            type="select"
                            name="portStatuses.phoneNumber.wholesaleStatus"
                            label="Wholesale Status"
                            value={props.workingOrder.portStatuses.phoneNumber.wholesaleStatus}
                            handleChange={handleChange}
                            options={[
                                {id: "Port Request Submitted", value: "Port Request Submitted"},
                                {id: "Port Request Approved", value: "Port Request Approved"},
                                {id: "Port Completed", value: "Port Completed"},
                            ]}
                            disabled={locked}
                        />
                        <FormFieldMicro
                            type="date"
                            name="portStatuses.phoneNumber.focDate"
                            label="FOC Date"
                            value={props.workingOrder.portStatuses.phoneNumber.focDate}
                            handleChange={handleChange}
                            disabled={locked}
                        />
                    </div>
                </div>
                <div className="section-outline my-2">
                    <div className="row">
                        <div className="col">
                            <h5 className="text-start">Toll-Free Status</h5>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-3">
                        <FormFieldMicro
                            type="select"
                            name="portStatuses.tollFree.retailStatus"
                            label="Retail Status"
                            value={props.workingOrder.portStatuses.tollFree.retailStatus}
                            handleChange={handleChange}
                            options={[
                                {id: "Quote Requested", value: "Quote Requested"},
                                {id: "Quote Sent", value: "Quote Sent"},
                                {id: "LOA Requested", value: "LOA Requested"},
                                {id: "LOA Sent", value: "LOA Sent"},
                                {id: "LOA Completed", value: "LOA Completed"},
                            ]}
                            disabled={locked}
                        />
                        <FormFieldMicro
                            type="select"
                            name="portStatuses.tollFree.wholesaleStatus"
                            label="Wholesale Status"
                            value={props.workingOrder.portStatuses.tollFree.wholesaleStatus}
                            handleChange={handleChange}
                            options={[
                                {id: "Port Request Submitted", value: "Port Request Submitted"},
                                {id: "Port Request Approved", value: "Port Request Approved"},
                                {id: "Port Completed", value: "Port Completed"},
                            ]}
                            disabled={locked}
                        />
                        <FormFieldMicro
                            type="date"
                            name="portStatuses.tollFree.focDate"
                            label="FOC Date"
                            value={props.workingOrder.portStatuses.tollFree.focDate}
                            handleChange={handleChange}
                            disabled={locked}
                        />
                    </div>
                </div>
                {props.workingOrder.enableFax ? 
                <div className="section-outline my-2">
                    <div className="row">
                        <div className="col">
                            <h5 className="text-start">Fax Status</h5>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-3">
                        <FormFieldMicro
                            type="select"
                            name="portStatuses.faxNumber.retailStatus"
                            label="Retail Status"
                            value={props.workingOrder.portStatuses.faxNumber.retailStatus}
                            handleChange={handleChange}
                            options={[
                                {id: "Quote Requested", value: "Quote Requested"},
                                {id: "Quote Sent", value: "Quote Sent"},
                                {id: "LOA Requested", value: "LOA Requested"},
                                {id: "LOA Sent", value: "LOA Sent"},
                                {id: "LOA Completed", value: "LOA Completed"},
                            ]}
                            disabled={locked}
                        />
                        <FormFieldMicro
                            type="select"
                            name="portStatuses.faxNumber.wholesaleStatus"
                            label="Wholesale Status"
                            value={props.workingOrder.portStatuses.faxNumber.wholesaleStatus}
                            handleChange={handleChange}
                            options={[
                                {id: "Port Request Submitted", value: "Port Request Submitted"},
                                {id: "Port Request Approved", value: "Port Request Approved"},
                                {id: "Port Completed", value: "Port Completed"},
                            ]}
                            disabled={locked}
                        />
                        <FormFieldMicro
                            type="date"
                            name="portStatuses.faxNumber.focDate"
                            label="FOC Date"
                            value={props.workingOrder.portStatuses.faxNumber.focDate}
                            handleChange={handleChange}
                            disabled={locked}
                        />
                    </div>
                </div>
                :null}
                {props.workingOrder.enableSms ? 
                <div className="section-outline my-2">
                    <div className="row">
                        <div className="col">
                            <h5 className="text-start">SMS Status</h5>
                        </div>
                    </div>
                    <div className="row row-cols-1 row-cols-md-3 g-3">
                        <FormFieldMicro
                            type="select"
                            name="portStatuses.sms.retailStatus"
                            label="Retail Status"
                            value={props.workingOrder.portStatuses.sms.retailStatus}
                            handleChange={handleChange}
                            options={[
                                {id: "Quote Requested", value: "Quote Requested"},
                                {id: "Quote Sent", value: "Quote Sent"},
                                {id: "LOA Requested", value: "LOA Requested"},
                                {id: "LOA Sent", value: "LOA Sent"},
                                {id: "LOA Completed", value: "LOA Completed"},
                            ]}
                            disabled={locked}
                        />
                        <FormFieldMicro
                            type="select"
                            name="portStatuses.sms.wholesaleStatus"
                            label="Wholesale Status"
                            value={props.workingOrder.portStatuses.sms.wholesaleStatus}
                            handleChange={handleChange}
                            options={[
                                {id: "Port Request Submitted", value: "Port Request Submitted"},
                                {id: "Port Request Approved", value: "Port Request Approved"},
                                {id: "Port Completed", value: "Port Completed"},
                            ]}
                            disabled={locked}
                        />
                        <FormFieldMicro
                            type="date"
                            name="portStatuses.sms.focDate"
                            label="FOC Date"
                            value={props.workingOrder.portStatuses.sms.focDate}
                            handleChange={handleChange}
                            disabled={locked}
                        />
                    </div>
                </div>
                :null}
                </>
                :null}
                <div className="section-outline">
                    <div className="row">
                        <div className="col">
                            <h5 className="text-start">Attachments</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-start">
                            {!props.workingOrder.attachments || props.workingOrder.attachments.length === 0 ? <span className="text-muted">There are no attachments yet</span> : <ol className="text-start">
                                {props.workingOrder.attachments.map((attachment, i) => <li key={i}>
                                    <span>
                                        <a href="#" onClick={(e) => {e.preventDefault(); downloadAttachment(attachment)}}>
                                            {attachment.filename}
                                        </a>
                                        &nbsp;
                                        ({sizeAbbreviation(attachment.size)})
                                        &nbsp;
                                        <button className="btn btn-sm btn-danger" onClick={(e) => {e.preventDefault(); deleteAttachment(attachment)}} disabled={props.isSaving}>
                                            <i className="fas fa-times"/>
                                        </button>
                                    </span>
                                </li>)}
                            </ol>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-3">
                {/** Sidebar: Status, Actionees, Notes, Author, Timestamp(s) */}
                <FormFieldMicro
                    type="select"
                    name="status"
                    label="Status"
                    value={props.workingOrder.status}
                    handleChange={handleChange}
                    options={[
                        {id: "New", value: "New"},
                        {id: "In-Progress", value: "In-Progress"},
                        {id: "Ready to Bill", value: "Ready to Bill"},
                        {id: "Waiting on Customer", value: "Waiting on Customer"},
                        {id: "Delay", value: "Delay"},
                        {id: "Completed", value: "Completed"},
                        {id: "Cancelled", value: "Cancelled"},
                    ]}
                    fullWidth
                    excludeNoneSelected
                />
                <FormFieldMicro
                    type="select"
                    name="onboardingPerson"
                    label="Onboarding Person"
                    value={props.workingOrder.onboardingPerson.email}
                    handleChange={handleChange}
                    disabled={locked}
                    options={props.users.filter((user) => user.groups.includes("Executive") || user.email.toLowerCase() === "tyler@gocbit.com").map((user) => { return {id: user.email, value: `${user.first} ${user.last}`}})}
                    fullWidth
                />
                {props.type !== "Disconnect" ? 
                <FormFieldMicro
                    type="component"
                    name="salesperson"
                    label="Salesperson"
                    fullWidth
                >
                    <ExpandableDropdown 
                        listName="VOIP_ORDER_SALESPERSONS"
                        htmlName="salesperson"
                        value={props.workingOrder.salesperson}
                        handleChange={handleChange}
                        disabled={locked}
                        banners={banners}
                        modalContext={modaling}
                    />
                </FormFieldMicro>
                :null}
                <button className="btn btn-primary w-100 mb-1" onClick={handleSave} disabled={props.saveDisabled}>
                    <i className={props.isSaving ? "fas fa-spinner" : "fas fa-floppy-disk"}/>&nbsp;{props.isSaving ? "Saving..." : "Save"}
                </button>
                <button className="btn btn-primary w-100 mb-1" onClick={openEmailModal} disabled={props.isSaving}>
                    <i className="fas fa-envelope"/>&nbsp;{props.workingOrder.emailSentDate ? "Send Customer Info Again" : "Send Customer Info"}
                </button>
                <button className="btn btn-primary w-100 mb-1" onClick={openFileUploadModal} disabled={props.isSaving}>
                    <i className="fas fa-file"/>&nbsp;Upload Attachment
                </button>
                <button className="btn btn-primary w-100 mb-1" onClick={createSyncroTicket} disabled={props.isSaving || !props.savedOrder}>
                    <svg className="filter-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 64C28.7 64 0 92.7 0 128v64c0 8.8 7.4 15.7 15.7 18.6C34.5 217.1 48 235 48 256s-13.5 38.9-32.3 45.4C7.4 304.3 0 311.2 0 320v64c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V320c0-8.8-7.4-15.7-15.7-18.6C541.5 294.9 528 277 528 256s13.5-38.9 32.3-45.4c8.3-2.9 15.7-9.8 15.7-18.6V128c0-35.3-28.7-64-64-64H64zm64 112l0 160c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16V176c0-8.8-7.2-16-16-16H144c-8.8 0-16 7.2-16 16zM96 160c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V160z"/></svg>&nbsp;Create Syncro Ticket
                </button>
                <button className="btn btn-success w-100 mb-1" onClick={handlePushToCustomer} disabled={props.isSaving || !chimera.deepEqual(props.savedOrder, props.workingOrder) || (props.workingOrder.type !== "New Customer" && !props.workingOrder.customerRef)}>
                    <i className="fas fa-arrow-right"/>&nbsp;Push to Customer
                </button>
                {props.workingOrder.customerRef ? 
                    <div>
                        <a href={`/customers/${props.workingOrder.customerRef}`} rel="noreferrer" target="_blank">
                            <i className="fas fa-arrow-right"/>
                            &nbsp;Open Customer in New Tab
                        </a>
                    </div>
                :null}
                {props.workingOrder.author ? 
                    <p className="text-muted">Order created by {props.workingOrder.author.first} {props.workingOrder.author.last} ({props.workingOrder.author.email}) at {(new Date(props.workingOrder.createdAt)).toLocaleString()}</p>
                : null}
                {props.workingOrder.emailSentDate ? <p className="text-muted ms-2">Email was sent to customer on {(new Date(props.workingOrder.emailSentDate)).toLocaleString()}</p> : null}
                {props.workingOrder.ticketCreatedDate ? <p className="text-muted ms-2">Syncro ticket created on {(new Date(props.workingOrder.ticketCreatedDate)).toLocaleString()}</p> : null}
                <MiniNotes notes={props.workingOrder.notes} setNotes={setNotes} disabled={props.isSaving}/>
            </div>
        </div>
    )
}

const VoipOrderFormModal = props => {
    const DEFAULT_ORDER = {
        type: props.type,
        status: "New",
        customerRef: '',
        customerName: '',
        customerLocationNickname: '',
        newLocationNickname: '',
        businessPhone: '',
        serviceAddress: {
            street1: '',
            street2: '',
            city: '',
            state: 'WA',
            county: '',
            zip: ''
        },
        poc: {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        },
        billingAddress: {
            street1: '',
            street2: '',
            city: '',
            state: 'WA',
            county: '',
            zip: ''
        },
        billingPoc: {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        },
        billStartDate: '',
        contractTerm: 'NOT SET',
        portStatuses: {
            phoneNumber: {
                focDate: '',
                wholesaleStatus: 'NOT SET',
                retailStatus: 'NOT SET',
            },
            tollFree: {
                focDate: '',
                wholesaleStatus: 'NOT SET',
                retailStatus: 'NOT SET',
            },
            faxNumber: {
                focDate: '',
                wholesaleStatus: 'NOT SET',
                retailStatus: 'NOT SET',
            },
            sms: {
                focDate: '',
                wholesaleStatus: 'NOT SET',
                retailStatus: 'NOT SET',
            }
        },
        enableSms: false,
        enableFax: false,
        enableInternational: false,
        products: [],
        hardware: [],
        onboardingPerson: {
            first: '',
            last: '',
            email: ''
        },
        salesperson: '',
        notes: []
    }
    const [workingOrder, setWorkingOrder] = useState(props.orderId ? null : DEFAULT_ORDER);
    const [type, setType] = useState(props.type ? props.type : null);
    const [savedOrder, setSavedOrder] = useState(null);
    const [modified, setModified] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [saveMode, setSaveMode] = useState(props.orderId ? 'PUT' : 'POST');
    const [users, setUsers] = useState(null);
    const [customers, setCustomers] = useState(null);
    const [tsPairs, setTsPairs] = useState(null);
    const modaling = useContext(ModalContext);

    useEffect(() => {
        if(props.orderId) {
            chimera.callAPI(undefined, `/api/voiporders/${props.orderId}`)
            .then(order => {
                setWorkingOrder(order);
                setSavedOrder(order);
                setType(order.type);
            })
        }
    }, [props.orderId]);

    useEffect(() => {
        if(tsPairs === null) {
            chimera.callAPI(undefined, '/api/tspairs')
            .then(pairs => setTsPairs(pairs))
            .catch(err => {
                console.error(err);
                alert("ERROR: Failed to read T/S Pairs; cannot continue");
                modaling.setModal(null);
            })
        }
    }, [tsPairs]);

    useEffect(() => {
        if(users === null) {
            chimera.callAPI(undefined, '/api/users')
            .then(users => setUsers(users))
            .catch(err => {
                console.error(err);
                alert("Failed to read users. Won't be able to select Assignee");
                setUsers([]);
            })
        }
    }, [users]);

    useEffect(() => {
        if(customers === null && props.type !== "New Customer") {
            chimera.callAPI(undefined, '/api/customers')
            .then(customers => setCustomers(customers))
            .catch(err => {
                console.error(err);
                alert("Failed to read customers. Cannot open form.");
            })
        }
    }, [customers]);

    const saveDisabled = () => {
        if(isSaving) return true;
        if(saveMode === 'PUT') {
            return chimera.deepEqual(workingOrder, savedOrder);
        }
        else {
            return !modified;
        }
    }

    const handleSave = (resolve, reject, order) => {
        //event.preventDefault();
        setIsSaving(true);
        // On any save, the Check-In Date is pushed out 7 days.
        let body = JSON.parse(JSON.stringify(order ? order : workingOrder));
        let checkInDate = new Date();
        checkInDate.setDate(checkInDate.getDate() + 7);
        body.checkInDate = checkInDate.toISOString().substring(0,10);
        chimera.callAPI(undefined, `/api/voiporders${saveMode === "PUT" ? `/${order ? order._id : workingOrder._id}` : ''}`, saveMode, body)
        .then(savedOrder => {
            setIsSaving(false); // this line present in both clauses BEFORE the resolve/reject is called prevents memory leaks if resolve/reject unmounts the component
            if(resolve) resolve(savedOrder);
        })
        .catch(err => {
            setIsSaving(false);
            if(reject) reject(err);
        });
    }

    const saveAndCloseFunc = (resolve, reject) => {
        const dismiss = (event) => {
            event.preventDefault();
            modaling.backtrack();
        }
        modaling.setModal(<Modal choices={[]} dismiss={dismiss}>
            <LoadingSpinner size={75}/>
        </Modal>)
        handleSave(resolve, reject);
    }

    const saveAndCloseResolve = (savedOrder) => {
        setWorkingOrder(savedOrder);
        setSavedOrder(savedOrder);
        setSaveMode('PUT');
        modaling.setModal(null);
    }

    const saveAndCloseReject = (err) => {
        console.error(err);
        alert("ERROR: Failed to save changes");
        modaling.backtrack();
    }

    const choices = [
        choiceCancel(props, !saveDisabled(), <>
            <h3>You have unsaved changes</h3>
            <p>Are you sure you want to close this form? Your changes will not be saved.</p>
        </>, "Close", {func: saveAndCloseFunc, resolve: saveAndCloseResolve, reject: saveAndCloseReject})
    ]

    if(saveMode === 'PUT') {
        choices.push(choiceDelete(props, () => {
            modaling.setModal(<Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.setModal(null)}}>
                <LoadingSpinner size={75}/>
            </Modal>);
            chimera.callAPI(undefined, `/api/voiporders/${savedOrder._id}`, 'DELETE')
            .then(_ => {
                props.banners.addBanner('info', 'The order has been deleted.', 'Success');
            })
            .catch(err => {
                console.error(err);
                //alert("ERROR: Failed to delete the order");
                props.banners.addBanner('danger', 'Failed to delete the order.', 'Error');
            })
            .finally(() => {
                props.onClose();
                modaling.setModal(null);
            })
        }, {label: "Delete Permanently"}))
    }

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <BannerLog>
                {workingOrder && users && tsPairs && (type === "New Customer" || customers) ? 
                <VoipOrderFormBody {...props} 
                    workingOrder={workingOrder} setWorkingOrder={setWorkingOrder} 
                    savedOrder={savedOrder} setSavedOrder={setSavedOrder}
                    modifed={modified} setModified={setModified}
                    isSaving={isSaving} setIsSaving={setIsSaving}
                    saveMode={saveMode} setSaveMode={setSaveMode}
                    saveDisabled={saveDisabled()}
                    handleSave={handleSave}
                    users={users}
                    customers={customers}
                    tsPairs={tsPairs}
                    type={type}
                />
                : <LoadingSpinner size={50}/>}
            </BannerLog>
        </Modal>
    )
}

export default VoipOrderFormModal;