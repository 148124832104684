import React, {useState} from "react";

import Modal, {choiceCancel} from '../Modal';
import FormFieldMicro from "../FormFieldMicro";
import chimera from "../../chimera";

const ConvertTypeModal = props => {
    const [newType, setNewType] = useState("New Customer");
    const [isSaving, setIsSaving] = useState(false);

    const handleChange = (e) => {
        if(e.target.name === 'newType') {
            setNewType(e.target.value);
        }
    }

    const choices = [
        choiceCancel(props)
    ]

    const run = async() => {
        setIsSaving(true);
        // for each order, make a PUT request to the correct endpoint and change them all to the new type
        for(const order of props.orders) {
            try {
                await chimera.callAPI(undefined, `/api/${props.orderClass}orders/${order._id}`, 'PUT', {type: newType});
            }
            catch(e) {
                console.error(e);
                props.bannerContext.addBanner('danger', `Failed to save changes to Order ID ${order._id} for ${order.customerName}`);
            }
        }
        props.callback();
    }

    const runButton = () => {
        return <button className="btn btn-primary" onClick={(e) => {e.preventDefault(); run()}} disabled={isSaving}>
            <i className={isSaving ? 'fas fa-spinner' : 'fas fa-arrow-right'}/>&nbsp;{isSaving ? 'Saving...' : `Convert Order${props.orders.length > 1 ? 's' : ''}`}
        </button>
    }

    const abbreviateType = (type) => {
        switch(type) {
            case "New Customer":
                return "New";
            case "Modify Service":
                return "Mod.";
            case "Add Location":
                return "Order";
            case "Disconnect":
                return "Disco.";
            default:
                return "(None)";
        }
    }

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Convert Type</h3>
            <FormFieldMicro
                type="select"
                name="newType"
                label="New Type"
                value={newType}
                handleChange={handleChange}
                disabled={isSaving}
                options={[
                    {id: 'New Customer', value: 'New'},
                    {id: 'Modify Service', value: 'Modify'},
                    {id: 'Disconnect', value: 'Disconnect'}
                ]}
                fit
            />
            <p><b>NOTE:</b> If you convert from New to Modify or Disconnect, you need to re-select the Customer from within the form after converting.</p>
            {runButton()}
            <p>The following orders will be converted:</p>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Customer</th>
                        <th>Current Type</th>
                        <th>New Type</th>
                    </tr>
                </thead>
                <tbody>
                    {props.orders.map((order, i) => <tr key={i}>
                        <td>{order._id}</td>
                        <td>{order.customerName}</td>
                        <td>{abbreviateType(order.type)}</td>
                        <td>{abbreviateType(newType)}</td>
                    </tr>)}
                </tbody>
            </table>
            {runButton()}
        </Modal>
    );
}

export default ConvertTypeModal;