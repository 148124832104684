import React, {useContext} from 'react';

import ModalContext from '../ModalContext';
import ExportModal from '../ExportModal';
import AlertsContext from '../AlertsContext';
import Tooltip from '../Tooltip';
import ObjectTable from '../ObjectTable';
import SubcontractorOrderFormModal from './SubcontractorOrderFormModal';

const getDescriptionPreview = (order) => {
    return order.items.length > 0 ? order.items[0].description.substring(0, 20) + (order.items[0].description.length > 20 ? '...' : '') : '(No items)';
}

const SubcontractorOrderRow = ({object, clickedObject}) => {
    const alertsContext = useContext(AlertsContext);
    const statusColor = (status) => {
        switch(status) {
            case "New":
                return "info";
            case "Scheduled":
                return "primary";
            case "Invoiced":
                return "warning";
            case "Ready to Pay Sub":
                return "danger";
            case "Ready to Bill Customer":
                return "success";
            case "Completed":
                return "secondary";
            default:
                return "light";
        }
    }

    const renderFlag = () => {
        const reason = alertsContext.orderReason(object._id, 'subcontractor');
        if(!reason) return null;
        else return <>&nbsp;<Tooltip text={reason}><i className="fas fa-flag text-danger"/></Tooltip></>
    }

    return (
        <>
            <td className={`cursor-pointer table-${statusColor(object.status)}`} onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.status}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.customer.displayName}{renderFlag()}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.subcontractor.name}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                <div className="tooltip-container border-0">
                    <span className="tooltip-text p-2">
                        {object.items.map((item, i) => `Item ${i+1}: ${item.description}`).join('\n\n')}
                    </span>
                    {getDescriptionPreview(object)}
                </div>
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.qbInvoice.number}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.qbInvoice.id ? (object.qbInvoice.status !== "NOT SET" ? object.qbInvoice.status : "(Check QB)") : ""}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.dateRequired ? (new Date(`${object.dateRequired}T13:00:00`)).toLocaleDateString() : ''}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.orderNumber}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.ticketNumber}
            </td>
        </>
    )
}

/**
 * 
 * @param {Array} props.orders The list of orders to display
 * @param {Function} props.clickedOrder A function that takes the clicked order as an argument
 */
const SubcontractorOrdersTable = props => {
    const modaling = useContext(ModalContext);
    const alerts = useContext(AlertsContext);

    const openNewOrderForm = (_) => {
        modaling.setModal(null);
        modaling.setModal(<SubcontractorOrderFormModal parentBanners={props.parentBanners} modalContext={modaling} onClose={() => {props.setOrders(null); alerts.reload()}}/>)
    }

    /** TODO: Implement exporting for SubcontractorOrders */
    const openExportModal = (selectedObjects) => {
        modaling.setModal(<ExportModal objects={selectedObjects} model="subcontractorOrders"/>);
    }

    return (
        <ObjectTable 
            id="subcontractorOrders"
            cols={[
                {
                    label: 'Status',
                    sort: (ascending) => {
                        return (a, b) => {
                            const values = {
                                'NOT SET': 0,
                                'New': 1,
                                'Scheduled': 2,
                                'Invoiced': 3,
                                'Ready to Pay Sub': 4,
                                'Ready to Bill Customer': 5,
                                'Completed': 10,
                            }
                            return values[`${a.status}`] < values[`${b.status}`] ? (ascending ? -1 : 1) : (ascending ? 1 : -1);
                        }
                    }
                },
                {
                    label: 'Customer Name',
                    sort: (ascending) => {
                        return (a, b) => a.customer.displayName < b.customer.displayName ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'Subcontractor',
                    sort: (ascending) => {
                        return (a, b) => a.subcontractor.name < b.subcontractor.name ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'Description',
                    sort: (ascending) => {
                        return (a, b) => getDescriptionPreview(a) < getDescriptionPreview(b) ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'QB Invoice #',
                    sort: (ascending) => {
                        return (a, b) => a.qbInvoice.number < b.qbInvoice.number ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'QB Invoice Status',
                    sort: (ascending) => {
                        return (a, b) => {
                            const values = {
                                'Paid': 0,
                                'Unpaid': 1,
                                'NOT SET': 2,
                                '': 3
                            }
                            return values[`${a.qbInvoice.status}`] < values[`${b.qbInvoice.status}`] ? (ascending ? -1 : 1) : (ascending ? 1 : -1);
                        }
                    }
                },
                {
                    label: 'Date Required',
                    sort: (ascending) => {
                        return (a, b) => a.dateRequired < b.dateRequired ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'Order #',
                    sort: (ascending) => {
                        return (a, b) => a.orderNumber < b.orderNumber ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'Ticket #',
                    sort: (ascending) => {
                        return (a, b) => a.ticketNumber < b.ticketNumber ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                }
            ]}
            objects={props.orders}
            actions={[
                {label: 'Export', func: openExportModal},
            ]}
            filters={[
                {label: 'Default', value: 'Default', func: (e) => e.status !== "Completed"},
                {label: 'New', value: 'New', func: (e) => e.status === "New"},
                {label: 'Scheduled', value: 'Scheduled', func: (e) => e.status === "Scheduled"},
                {label: 'Invoiced', value: 'Invoiced', func: (e) => e.status === "Invoiced"},
                {label: 'Ready to Pay Sub', value: 'Ready to Pay Sub', func: (e) => e.status === "Ready to Pay Sub"},
                {label: 'Ready to Bill Customer', value: 'Ready to Bill Customer', func: (e) => e.status === "Ready to Bill Customer"},
                {label: 'Completed', value: 'Completed', func: (e) => e.status === "Completed"},
                {label: 'Everything', value: 'Everything', func: (_) => true},
            ]}
            btns={[
                {label: 'Order', func: openNewOrderForm}
            ]}
            rowElement={SubcontractorOrderRow}
            clickedObject={props.clickedOrder}
            search
            paginated
            defaultSortByColName="Status"
            defaultFilterSetting="Default"
            defaultSortAscending={true}
        />
    )
}

export default SubcontractorOrdersTable