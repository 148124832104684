import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import chimera from '../chimera';
import BannerContext, { BannerLog } from './BannerLogContext';
import FormField from './FormField';
import PocDisplay from './CustomerPage/PocDisplay';
import AddressDisplay from './CustomerPage/AddressDisplay';
import ModalContext from './ModalContext';
import Modal from './Modal';
import StringList from './StringList';
import LoadingSpinner from './LoadingSpinner';
import IPListTable from './CustomerPage/IPListTable';

const NewCustomerBody = props => {
    const [customer, setCustomer] = useState(props.customer ? props.customer : new chimera.CommercialCustomer());

    const [isSaving, setIsSaving] = useState(false);
    const [saveBtnLabel, setSaveBtnLabel] = useState("Create Customer");
    const [willSyncro, setWillSyncro] = useState(true);
    const [willQb, setWillQb] = useState(true);
    const [willUnity, setWillUnity] = useState(false);
    const [willPax8, setWillPax8] = useState(false);
    const [willDuo, setWillDuo] = useState(false);
    const [dupeBtnLabel, setDupeBtnLabel] = useState("Check for Duplicates");
    const [dupeBtnIcon, setDupeBtnIcon] = useState("fas fa-magnifying-glass");
    const [dupeBtnDisabled, setDupeBtnDisabled] = useState(false);
    const [dupeResultColor, setDupeResultColor] = useState("text-body");
    const [dupeResultIcon, setDupeResultIcon] = useState("");
    const [dupeResultText, setDupeResultText] = useState("");
    const [shippingSameAsService, setShippingSameAsService] = useState(false);
    const [billingSameAsService, setBillingSameAsService] = useState(false);
    const [unityDomain, setUnityDomain] = useState("");
    const [pax8Domain, setPax8Domain] = useState("");
    const [ipClaims, setIpClaims] = useState({});
    const [controller] = useState(props.controller ? props.controller : new AbortController());
    const [signal] = useState(props.signal ? props.signal : controller.signal);
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        if(!isSaving) {
            setSaveBtnLabel("Create Customer");
        }
    }, [isSaving]);

    const handleChange = event => {
        if(event.target.type !== "checkbox") event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;

        let newCustomer = new chimera.CommercialCustomer(customer.rawCustomerData);

        if(name === "businessName" || name.includes("platformCheck") || name.includes("serviceTypes")) {
            setDupeResultColor("text-body");
            setDupeResultIcon("fas fa-circle-info");
            setDupeResultText("You haven't checked for duplicates yet.");
        }

        if(name === "platformCheckSyncro") {
            setWillSyncro(event.target.checked);
        }
        else if(name === "platformCheckQb") {
            setWillQb(event.target.checked);
        }
        else if(name === "platformCheckUnity") {
            setWillUnity(event.target.checked);
        }
        else if(name === "platformCheckPax8") {
            setWillPax8(event.target.checked);
        }
        else if(name === "platformCheckDuo") {
            setWillDuo(event.target.checked);
        }
        else if(name === "unityDomain") {
            setUnityDomain(value.replaceAll(/\s/g, ''));
        }
        else if(name === "pax8Domain") {
            setPax8Domain(value.trim());
        }
        else if(name === "shippingSameAsService") {
            if(event.target.checked) {
                const newAddress = newCustomer.serviceAddress;
                newCustomer.shippingAddress = newAddress;
                setShippingSameAsService(true);
            }
            else {
                setShippingSameAsService(false);
            }
        }
        else if(name === "billingSameAsService") {
            if(event.target.checked) {
                const newAddress = newCustomer.serviceAddress;
                newCustomer.billingAddress = newAddress;
                setBillingSameAsService(true);
            }
            else {
                setBillingSameAsService(false);
            }
        }
        else if(event.target.type === "checkbox") {
            chimera.setAttr(newCustomer, name, event.target.checked);
        }
        else if(event.target.type === "number" && name.includes("endpointPackages")) {
            chimera.setAttr(newCustomer, name, parseInt(value));
        }
        else if(event.target.type === "number") {
            chimera.setAttr(newCustomer, name, parseFloat(value));
        }
        else {
            if(name.toLowerCase().includes("phone")) {
                chimera.setAttr(newCustomer, name, value.replace(/\D/g, ''));
            }
            else {
                chimera.setAttr(newCustomer, name, value);
            }
        }

        if(name === "serviceTypes.msp") {
            setWillPax8(event.target.checked);
            setWillDuo(event.target.checked);
        }
        else if(name === "serviceTypes.voip") {
            setWillUnity(event.target.checked);
        }

        setCustomer(newCustomer);
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
        setSaveBtnLabel("Creating...");
        setIsSaving(true);
        banners.clearBanners();

        // Validate the customer
        const valid = await chimera.validateCustomer(signal, customer, banners);
        if(!valid) {
            setIsSaving(false);
            return;
        }

        // Create the customer
        try {
            const createdCustomer = await chimera.createCustomer(signal, customer, banners, {
                willSyncro: willSyncro,
                willQb: willQb,
                willUnity: willUnity,
                willPax8: willPax8,
                willDuo: willDuo,
                unityDomain: unityDomain,
                pax8Domain: pax8Domain,
                startSleepingCallback: () => {setSaveBtnLabel("Waiting...")},
                stopSleepingCallback: () => {setSaveBtnLabel("Creating...")},
                cleanupCallback: () => {setIsSaving(false)}
            });
            if(!props.hideIPTable && customer.serviceTypes.internet && !customer.technical.dhcp) {
                await chimera.callAPI(signal, '/api/iplists/claim', 'POST', {
                    ids: ipClaims['undefined'],
                    stamp: {
                        objectType: 'CustomerLocation',
                        objectId: createdCustomer.locations[0]._id,
                        name: createdCustomer.displayName
                    }
                })
            }
            props.createdCustomerCallback ? props.createdCustomerCallback(createdCustomer) : window.open(`/customers/${createdCustomer.accountNumber}`, '_self');
            return;
        }
        catch(e) {
            console.error(e);
            banners.addBanner('danger', 'Failed to create customer', 'Error');
            return;
        }
    }

    const addPoc = (basePath) => {
        let newPocs = [];
        const pocs = chimera.getAttr(customer, basePath);
        for(const poc of pocs) {
            newPocs.push(poc);
        }
        newPocs.push({
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        });

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newPocs
            },
            preventDefault: () => {}
        })
    }

    const deletePoc = (basePath, index) => {
        let newPocs = [];
        const pocs = chimera.getAttr(customer, basePath);
        for(let i = 0; i < pocs.length; i++) {
            if(i !== index) {
                newPocs.push(pocs[i]);
            }
        }

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newPocs
            },
            preventDefault: () => {}
        })
    }

    const addString = (basePath) => {
        let newStrings = [];
        const strings = chimera.getAttr(customer, basePath);
        for(const str of strings) {
            newStrings.push(str);
        }
        newStrings.push("");

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newStrings
            },
            preventDefault: () => {}
        });
    }

    const deleteString = (basePath, index) => {
        let newStrings = [];
        const strings = chimera.getAttr(customer, basePath);
        for(let i = 0; i < strings.length; i++) {
            if(i !== index)
                newStrings.push(strings[i]);
        }

        handleChange({
            target: {
                type: "Array",
                name: basePath,
                value: newStrings
            },
            preventDefault: () => {}
        });
    }

    const checkDupes = async(event) => {
        event.preventDefault();
        setDupeBtnDisabled(true);
        setDupeBtnIcon("fas fa-spinner");
        setDupeBtnLabel("Checking...");
        
        setDupeResultText("");
        setDupeResultIcon("");

        try {
            let matches = [];

            const searchTerm = customer.businessName.replace(/[&\$\+,\/\:;=?@#<>\[\]\{\}\|\\\^%-]/g, '').trim() // removes sensitive URL characters (and others) and trims result

            const chimeraMatches = await chimera.callAPI(signal, '/api/search/customers', 'POST', {
                businessName: searchTerm
            });

            for(const match of chimeraMatches) {
                matches.push({
                    name: match.displayName,
                    integrationIds: match.integrationIds,
                    inChimera: true,
                });
            }

            const blacklist = [
                "city",
                "the",
                "of",
                "and",
                "co",
                "llc",
                "inc",
                "dental",
                "corp"
            ]

            const tokens = searchTerm.split(' ').filter(token => !blacklist.includes(token.toLowerCase()));

            if(willSyncro) {
                // Get all Syncro matches
                // For each match,
                // // Check if it's ID matches an Integration ID already included.
                // // // If it does not, add to the matches array.
                // // // If it does, ignore it.

                for(const token of tokens) {
                    const syncroMatches = await chimera.callSyncroAPI(signal, 'GET', `/customers/like/${token}`, null, 
                    () => {
                        setDupeBtnLabel("Waiting out Syncro API limits...")
                    },
                    () => {
                        setDupeBtnLabel("Checking...")
                    });

                    for(const syncroMatch of syncroMatches) {
                        let alreadyIncluded = false;
                        for(const match of matches) {
                            if(match.integrationIds.syncro === syncroMatch.id.toString()) {
                                alreadyIncluded = true;
                                break;
                            }
                        }
                        if(!alreadyIncluded) {
                            matches.push({
                                name: syncroMatch.business_name,
                                integrationIds: {
                                    syncro: syncroMatch.id.toString()
                                },
                                inChimera: false // TODO: Accuracy can be improved by doing a lookup in case searching on each platform produced variable results
                            })
                        }
                    }
                }
            }

            if(willQb) {
                // Get all QuickBooks matches
                // For each match,
                // // Check if it's ID matches an Integration ID already included.
                // // // If it does not, add to the matches array.
                // // // If it does, ignore it.

                for(const token of tokens) {
                    const qbMatches = await chimera.callQuickBooksAPI(signal, `/api/qb/customers/like/${token}`);
                    for(const qbMatch of qbMatches) {
                        let alreadyIncluded = false;
                        for(let i = 0; i < matches.length; i++) {
                            if(matches[i].integrationIds.quickbooks === qbMatch.Id || matches[i].name === qbMatch.CompanyName) {
                                if(matches[i].name === qbMatch.CompanyName) {
                                    matches[i].integrationIds.quickbooks = qbMatch.Id;
                                }
                                alreadyIncluded = true;
                                break;
                            }
                        }
                        if(!alreadyIncluded) {
                            matches.push({
                                name: qbMatch.CompanyName,
                                integrationIds: {
                                    quickbooks: qbMatch.Id
                                },
                                inChimera: false // TODO: Accuracy can be improved by doing a lookup in case searching on each platform produced variable results
                            })
                        }
                    }
                }
            }

            let unityMatch = false;
            /**
            if(willUnity) {
                // This looks only for an exact match. A potential duplicate may be created if using a non-exact match.
                // // However, the probability of that case occurring, especially with the sensitivity of the other searches which are commonly enabled,
                // // is very low, and so it is not yet addressed by this code.
                const result = await chimera.callAPI(signal, `/api/unity/domains/${unityDomain}`);
                if(result.domain) {
                    unityMatch = true;
                }
            }
            */

            if(willPax8) {
                const pax8Companies = await chimera.callAPI(signal, '/api/pax8/companies');
                console.log(pax8Companies);
                let pax8Matches = [];
                for(const token of tokens) {
                    for(const company of pax8Companies) {
                        if(company.name.toLowerCase().includes(token.toLowerCase()) && !pax8Matches.find(c => c.id === company.id)) {
                            pax8Matches.push(company);
                        }
                    }
                }
                for(const pax8Match of pax8Matches) {
                    let alreadyIncluded = false;
                    for(let i = 0; i < matches.length; i++) {
                        if(matches[i].integrationIds.pax8 === pax8Match.id || matches[i].name === pax8Match.name) {
                            matches[i].integrationIds.pax8 = pax8Match.id;
                            alreadyIncluded = true;
                            break;
                        }
                    }
                    if(!alreadyIncluded) {
                        matches.push({
                            name: pax8Match.name,
                            integrationIds: {
                                pax8: pax8Match.id
                            },
                            inChimera: false // TODO: Accuracy can be improved by doing a lookup in case searching on each platform produced variable results
                        })
                    }
                }
            }

            if(willDuo) {
                const duoAccounts = await chimera.callAPI(signal, '/api/duo/accounts');
                let duoMatches = [];
                for(const token of tokens) {
                    for(const account of duoAccounts) {
                        if(account.name.toLowerCase().includes(token.toLowerCase()) && !duoMatches.find(match => match.account_id === account.account_id)) {
                            duoMatches.push(account);
                        }
                    }
                }
                for(const duoMatch of duoMatches) {
                    let alreadyIncluded = false;
                    for(let i = 0; i < matches.length; i++) {
                        if(matches[i].integrationIds.duo === duoMatch.account_id || matches[i].name === duoMatch.name) {
                            matches[i].integrationIds.duo = duoMatch.account_id;
                            alreadyIncluded = true;
                            break;
                        }
                    }
                    if(!alreadyIncluded) {
                        matches.push({
                            name: duoMatch.name,
                            integrationIds: {
                                duo: duoMatch.account_id
                            },
                            inChimera: false // TODO: Accuracy can be improved by doing a lookup in case searching on each platform produced variable results
                        })
                    }
                }
            }

            setDupeBtnDisabled(false);
            setDupeBtnIcon("fas fa-magnifying-glass");
            setDupeBtnLabel("Check for Duplicates");

            if(matches.length > 0) {
                const choices = [
                    {
                        btnColor: 'secondary',
                        btnInner: <span><i className="fas fa-check"/>&nbsp;Close</span>,
                        func: (event) => {
                            event.preventDefault();
                            modaling.setModal(null);
                        }
                    }
                ]

                const icon = (expr) => {
                    return <i className={expr ? "fas fa-triangle-exclamation text-danger" : "fas fa-check text-success"}/>
                }

                const modal = <Modal choices={choices} dismiss={choices[0].func}>
                    <div>
                        <h3>Heads Up!</h3>
                        <p>
                            The provided Business Name <strong>{customer.businessName}</strong> {unityMatch ? <span>and/or Unity Domain <strong>{unityDomain}</strong></span> : null} matched
                            against some existing customers. Please ensure you don't create any duplicates!
                        </p>
                        {unityMatch ? 
                        <>
                        <h4>
                            Unity
                        </h4>
                        <p className="text-danger">
                            The domain <strong>{unityDomain}</strong> is currently used in Unity.
                        </p>
                        <h4>
                            Other Platforms
                        </h4>
                        </>
                        :null}
                        <p>
                            <u>Legend:</u><br/>
                            <i className="fas fa-check text-success"/>: There is no conflicting record in this location and the customer can be created there safely.<br/>
                            <i className="fas fa-triangle-exclamation text-danger"/>: There is a possible duplicate record in this location. Proceed with caution.
                        </p>
                        <p>
                            <i style={{color: "#828282"}}>This search is very sensitive and prone to false positives. Sorry for the inconvenience.</i>
                        </p>
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Customer Name</th>
                                    <th>Chimera</th>
                                    {willSyncro ? <th>Syncro</th> : null}
                                    {willQb ? <th>QuickBooks</th> : null}
                                    {willPax8 ? <th>Pax8</th> : null}
                                    {willDuo ? <th>Duo</th> : null}
                                </tr>
                            </thead>
                            <tbody>
                                {matches.map((match, i) => 
                                    <tr key={i}>
                                        <td>{match.name}</td>
                                        <td>{icon(match.inChimera)}</td>
                                        {willSyncro ? <td>{icon(match.integrationIds.syncro !== "")}</td> : null}
                                        {willQb ? <td>{icon(match.integrationIds.quickbooks !== "")}</td> : null}
                                        {willPax8 ? <td>{icon(match.integrationIds.pax8 !== "")}</td> : null}
                                        {willDuo ? <td>{icon(match.integrationIds.duo !== "")}</td> : null}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Modal>;

                modaling.setModal(modal);

                setDupeResultColor("text-warning");
                setDupeResultText("Potential duplicates may exist.");
                setDupeResultIcon("fas fa-triangle-exclamation");
            }
            else {
                setDupeResultColor("text-success");
                setDupeResultText("No duplicates found.");
                setDupeResultIcon("fas fa-check");
            }
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
            }
            setDupeBtnDisabled(false);
            setDupeBtnIcon("fas fa-magnifying-glass");
            setDupeBtnLabel("Check for Duplicates");

            setDupeResultColor("text-danger");
            setDupeResultText("An error occurred and the search could not be performed.");
            setDupeResultIcon("fas fa-times");
        }
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const strInterface = (basePath, label, placeholder, description) => {
        const strings = chimera.getAttr(customer, basePath);
        return(
            <div className="row justify-content-center">
                <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                    <div className="form-field">
                        <div className="form-label">{label}:</div>
                        {strings.map((str, i) => 
                            <div className="mb-1 d-flex flex-row" key={i}>
                                <input className="form-control" type="text" name={`${basePath}[${i}]`} value={str} placeholder={placeholder} onChange={handleChange} disabled={isSaving} onBlur={trimOnBlur}/>
                                <button className="btn btn-danger ms-2" onClick={(event) => {event.preventDefault(); deleteString(basePath, i)}}><i className="fas fa-minus"/></button>
                            </div>
                        )}
                        <button className="btn btn-success" onClick={(event) => {event.preventDefault(); addString(basePath)}}>
                            <i className="fas fa-plus"/>
                        </button>
                        <div className="form-text">
                            {description}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const highlightField = (attr) => {
        if(!props.customer) return false;
        if(attr.toLowerCase().endsWith('address')) {
            return !props.customer[attr].street1 || !props.customer[attr].city || !props.customer[attr].county || !props.customer[attr].zip;
        }
        else if(attr.toLowerCase().endsWith('emails')) {
            //console.log(`analying: ${attr}`)
            //console.log(attr);
            return props.customer[attr].length === 0 || !props.customer[attr][0];
        }
        else {
            return !props.customer[attr];
        }
    }

    return (
        <form id="newCustomerForm" onSubmit={handleSubmit} noValidate>
            <div className="row">
                <div className="col">
                    <FormField
                        type="text"
                        name="displayName"
                        label="Display Name"
                        value={customer.displayName}
                        description="The name shown for all display purposes."
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        required
                    />
                    <FormField
                        type="text"
                        name="businessName"
                        label="Business Name"
                        value={customer.businessName}
                        description="The name shown for legal purposes."
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-field">
                        <span className="form-label"><span className="red">*</span>&nbsp;Service Types:</span>
                        <div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="serviceTypes.internet" id="internetService" checked={customer.serviceTypes.internet} onChange={handleChange} disabled={isSaving}/>
                                <label className="form-check-label" htmlFor="internetService">
                                    Internet
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="serviceTypes.voip" id="voipService" checked={customer.serviceTypes.voip} onChange={handleChange} disabled={isSaving}/>
                                <label className="form-check-label" htmlFor="voipService">
                                    VoIP
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="serviceTypes.msp" id="mspService" checked={customer.serviceTypes.msp} onChange={handleChange} disabled={isSaving}/>
                                <label className="form-check-label" htmlFor="mspService">
                                    MSP
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" name="serviceTypes.video" id="videoService" checked={customer.serviceTypes.video} onChange={handleChange} disabled={isSaving}/>
                                <label className="form-check-label" htmlFor="videoService">
                                    Video
                                </label>
                            </div>
                        </div>
                        <span className="form-text">The services to be provided to the customer.</span>
                    </div>
                </div>
            </div>
            <div className="form-field">
                <div className="row">
                    <div className="col">
                        <div className="form-label">Also Create Profiles For:</div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="platformCheckQb" id="qbCheck" checked={willQb} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="qbCheck">
                                QuickBooks
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="platformCheckSyncro" id="syncroCheck" checked={willSyncro} onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="syncroCheck">
                                Syncro
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="platformCheckUnity" id="unityCheck" checked={willUnity && customer.serviceTypes.voip} onChange={handleChange} disabled={!customer.serviceTypes.voip}/>
                            <label className="form-check-label" htmlFor="unityCheck">
                                Unity
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="platformCheckPax8" id="pax8Check" checked={willPax8 && customer.serviceTypes.msp} onChange={handleChange} disabled={!customer.serviceTypes.msp}/>
                            <label className="form-check-label" htmlFor="pax8Check">
                                Pax8
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" name="platformCheckDuo" id="duoCheck" checked={willDuo && customer.serviceTypes.msp} onChange={handleChange} disabled={!customer.serviceTypes.msp}/>
                            <label className="form-check-label" htmlFor="duoCheck">
                                Duo
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                        <div className="form-text">
                            This section shows what records will be created in integrated platforms to
                            correspond with the new customer. You may toggle them off, but note that the customer
                            will fail to integrate with that platform unless you manually configure it later.
                            If a customer already exists on a particular platform, uncheck that platform and manually
                            integrate it later.
                        </div>
                    </div>
                </div>
            </div>
            {willUnity ?
            <div className="row">
                <div className="col">
                    <FormField
                        type="text"
                        name="unityDomain"
                        label="(Optional) Unity Domain"
                        value={unityDomain}
                        description="If it already exists, provide the Domain Name used in Unity. You can assign/re-assign the domain to this customer at any time in the future."
                        handleChange={handleChange}
                        placeholder="Domain"
                        onBlur={trimOnBlur}
                    />
                </div>
            </div>
            :null}
            <div className="row">
                <div className="col">
                    <div className="form-field">
                        <div className="form-label">Check for Duplicates</div>
                        <button className="btn btn-primary" onClick={checkDupes} disabled={dupeBtnDisabled || isSaving || (customer.businessName === "")}>
                            <i className={dupeBtnIcon}/>
                            &nbsp;{dupeBtnLabel}
                        </button>
                        <p className={dupeResultColor}><i className={dupeResultIcon}/>&nbsp;{dupeResultText}</p>
                        <div className="form-text">
                            Duplication checking uses the Business Name only as its query.
                        </div>
                    </div>
                </div>
            </div>
            {willPax8 ? 
            <div className="row">
                <div className="col">
                    <FormField
                        type="text"
                        name="pax8Domain"
                        label="Pax8 Domain"
                        value={pax8Domain}
                        description="The Customer's website domain to be stored in Pax8."
                        handleChange={handleChange}
                        placeholder="Website"
                        onBlur={trimOnBlur}
                        required
                    />
                </div>
            </div>
            :null}
            {customer.serviceTypes.voip || customer.serviceTypes.internet || customer.serviceTypes.msp || customer.serviceTypes.video ? 
                <>
                <div className="row justify-content-center">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                        <div className="form-field">
                            <span className="form-label"><span className="red">*</span>&nbsp;Point of Contact:</span>
                            <PocDisplay poc={customer.poc} basePath="poc" baseValue={customer} onChange={handleChange} onBlur={trimOnBlur} disabled={isSaving} isEditing/>
                            <span className="form-text">The main point of contact as shown in Syncro.</span>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className={highlightField('businessPhone') ? "col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 bg-warning" : "col"}>
                        <FormField
                            type="text"
                            name="businessPhone"
                            label="Business Phone"
                            value={customer.businessPhone}
                            description="The main phone number for contacting the business."
                            handleChange={handleChange}
                            maxLength={11}
                            placeholder="Phone"
                            onBlur={trimOnBlur}
                            required
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className={highlightField('billingPhone') ? "col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 bg-warning" : "col"}>
                        <FormField
                            type="text"
                            name="billingPhone"
                            label="Billing Phone"
                            value={customer.billingPhone}
                            description="The phone number used for billing purposes."
                            handleChange={handleChange}
                            maxLength={11}
                            placeholder="Phone"
                            onBlur={trimOnBlur}
                            required
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className={highlightField('accountsPayableEmails') ? "col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 bg-warning" : "col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4"}>
                        <FormField
                            type="component"
                            name="accountsPayableEmails"
                            label="Accounts Payable Emails"
                            value={customer.accountsPayableEmails}
                            description="The emails used for invoicing."
                            fullWidth
                            required
                        >
                            <StringList obj={customer} basePath="accountsPayableEmails" handleChange={handleChange} placeholder="accountspayable@example.com"/>
                        </FormField>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                        <div className="form-field">
                            <label><span className="red">*</span> Service Address:</label>
                            <AddressDisplay addr={customer.billingAddress} basePath="serviceAddress" baseValue={customer} onChange={handleChange} disabled={isSaving} isEditing/>
                            <div className="form-text">
                                Service Address for the customer.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                        <div className="form-field">
                            <label><span className="red">*</span> Billing Address:</label>
                            <div className="form-check d-flex justify-content-center">
                                <input className="form-check-input" type="checkbox" name="billingSameAsService" id="billingSameAsService" checked={billingSameAsService} onChange={handleChange} disabled={isSaving}/>
                                &nbsp;
                                <label className="form-check-label" htmlFor="billingSameAsService">
                                    Same as Service Address
                                </label>
                            </div>
                            <AddressDisplay addr={customer.billingAddress} basePath="billingAddress" baseValue={customer} onChange={handleChange} disabled={isSaving || billingSameAsService} isEditing/>
                            <div className="form-text">
                                Billing Address for the customer (used in QuickBooks).
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className={highlightField('shippingAddress') ? "col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 bg-warning" : "col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4"}>
                        <div className="form-field">
                            <label><span className="red">*</span> Shipping Address:</label>
                            <div className="form-check d-flex justify-content-center">
                                <input className="form-check-input" type="checkbox" name="shippingSameAsService" id="shippingSameAsService" checked={shippingSameAsService} onChange={handleChange} disabled={isSaving}/>
                                &nbsp;
                                <label className="form-check-label" htmlFor="shippingSameAsService">
                                    Same as Service Address
                                </label>
                            </div>
                            <AddressDisplay addr={customer.billingAddress} basePath="shippingAddress" baseValue={customer} onChange={handleChange} disabled={isSaving || shippingSameAsService} isEditing/>
                            <div className="form-text">
                                Shipping Address for the customer.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                        <div className="form-field">
                            <div><span className="red">*</span>&nbsp;Billing POCs:</div>
                            {customer.billingPocs.map((poc, i) => 
                                <div key={i} className={i !== customer.billingPocs.length - 1 ? "section-outline mb-4" : "section-outline"}>
                                    <PocDisplay poc={poc} basePath={`billingPocs[${i}]`} baseValue={customer} onChange={handleChange} disabled={isSaving} isEditing handleDelete={(event) => {event.preventDefault(); deletePoc("billingPocs", i)}} series={i !== 0}/>
                                </div>
                            )}
                            <button className="btn btn-success mt-2 mb-2" onClick={(event) => {event.preventDefault(); addPoc("billingPocs")}}>
                                <i className="fas fa-plus"/>
                            </button>
                            <div className="form-text">
                                Points of contact for billing purposes. At least 1 contact is required.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row=">
                    <div className="col">
                        <FormField
                            type="select"
                            options={[
                                {id: "Business Hours Only", value: "Business Hours Only"},
                                {id: "Extended Weekdays", value: "Extended Weekdays"},
                                {id: "Extended Weekdays + Weekends", value: "Extended Weekdays + Weekends"}
                            ]}
                            name="onCallAfterHours"
                            label="On-Call / After Hours"
                            value={customer.onCallAfterHours ? customer.onCallAfterHours : "Business Hours Only"}
                            description="(Optional) Set policy for on call / after hours availability for this customer."
                            handleChange={handleChange}
                            excludeNoneSelected
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <FormField
                            type="number"
                            name="standardRate"
                            label="Standard Labor Rate"
                            value={customer.standardRate}
                            description="The rate to charge the customer for labor performed during normal business hours."
                            handleChange={handleChange}
                            min={0}
                            step={5}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <FormField
                            type="number"
                            name="afterHoursRate"
                            label="After-Hours Labor Rate"
                            value={customer.afterHoursRate}
                            description="The rate to charge the customer for labor performed outside of normal business hours, including weekends."
                            handleChange={handleChange}
                            min={0}
                            step={5}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <FormField
                            type="number"
                            name="laborCreditHours"
                            label="Labor Credit Hours"
                            value={customer.laborCreditHours ? customer.laborCreditHours : 0}
                            description="(Optional) Grant labor credits to this customer."
                            handleChange={handleChange}
                            min={0}
                            step={0.25}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <FormField
                            type="checkbox"
                            name="taxExempt"
                            label="Tax Exempt"
                            value={customer.taxExempt}
                            description="Whether or not the customer has a tax exemption."
                            handleChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className={highlightField('notificationEmails') ? "col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 bg-warning" : "col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4"}>
                        <div className="form-field">
                            <div className="form-label"><span className="red">*</span>&nbsp;Notification Emails:</div>
                            {customer.notificationEmails.map((email, i) => 
                                <div key={i} className="mb-1 d-flex flex-row">
                                    <input className="form-control" type="text" name={`notificationEmails[${i}]`} value={email} placeholder="Email" onChange={handleChange} disabled={isSaving} onBlur={trimOnBlur}/>
                                    {i !== 0 ? <button className="btn btn-danger ms-2" onClick={(event) => {event.preventDefault(); deleteString("notificationEmails", i)}}><i className="fas fa-minus"/></button> : null}
                                </div>
                            )}
                            <button className="btn btn-success" onClick={(event) => {event.preventDefault(); addString("notificationEmails")}}>
                                <i className="fas fa-plus"/>
                            </button>
                            <div className="form-text">The emails to be contacted for any automated notifications or alerts. At least 1 is required.</div>
                        </div>
                    </div>
                </div>
                {customer.serviceTypes.msp ? 
                <>
                    <div className="row">
                        <div className="col">
                            <FormField
                                type="textarea"
                                name="technical.networkSummary"
                                label="Network Summary"
                                value={chimera.getAttr(customer, 'technical.networkSummary')}
                                description="A summary of the customer's network."
                                handleChange={handleChange}
                                onBlur={trimOnBlur}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <FormField
                                type="checkbox"
                                name="technical.idrac"
                                label="iDRAC Enabled"
                                value={customer.technical.idrac}
                                description="Whether or not the customer iDRAC support enabled."
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                </>
                :null}
                {customer.serviceTypes.voip ? 
                    <div className="row">
                        <div className="col">
                            <FormField
                                type="number"
                                name="technical.voipLines"
                                label="# of Lines"
                                value={customer.technical.voipLines}
                                description="The maximum number of simultaneous calls this customer can have."
                                handleChange={handleChange}
                                min={0}
                                step={1}
                            />
                        </div>
                    </div>
                :null}
                {customer.serviceTypes.internet ? 
                    <>
                    {/** 
                    <div className="row">
                        <div className="col">
                            <FormField
                                type="select"
                                name="technical.provider"
                                label="Wholesale Provider"
                                value={chimera.getAttr(customer, 'technical.provider')}
                                description="The wholesale Fiber provider."
                                handleChange={handleChange}
                                options={[
                                    {id: "BPUD", value: "BPUD"},
                                    {id: "Noanet", value: "Noanet"},
                                    {id: "FPUD", value: "FPUD"},
                                    {id: "COR", value: "COR"},
                                    {id: "CBIT", value: "CBIT"},
                                ]}
                            />
                        </div>
                    </div>
                    */}
                    <div className="row">
                        <div className="col">
                            <FormField
                                type="select"
                                name="technical.service"
                                label="Service"
                                value={chimera.getAttr(customer, 'technical.service')}
                                description="The Fiber Internet speed."
                                handleChange={handleChange}
                                options={[
                                    {id: "100 Mbps", value: "100 Mbps"},
                                    {id: "200 Mbps", value: "200 Mbps"},
                                    {id: "250 Mbps", value: "250 Mbps"},
                                    {id: "500 Mbps", value: "500 Mbps"},
                                    {id: "1 Gbps", value: "1 Gbps"},
                                    {id: "2 Gbps", value: "2 Gbps"},
                                    {id: "10 Gbps", value: "10 Gbps"},
                                    {id: "30 Gbps", value: "30 Gbps"},
                                    {id: "Dark Fiber", value: "Dark Fiber"},
                                    {id: "Layer 2", value: "Layer 2"},
                                ]}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <FormField
                                type="text"
                                name="technical.managementIp"
                                label="Management IP"
                                value={chimera.getAttr(customer, 'technical.managementIp')}
                                description="The management IP."
                                handleChange={handleChange}
                                onBlur={trimOnBlur}
                            />
                        </div>
                    </div>
                    {strInterface("technical.cids", "CIDs", "CID", "(Optional) A list of associated CIDs.")}
                    {/**
                    {strInterface("technical.ips", "IPs", "IP Address", "(Optional) A list of associated IP addresses.")}
                    {strInterface("technical.gateways", "Gateways", "Gateway", "(Optional) A list of associated gateways.")}
                    {strInterface("technical.subnetMasks", "Subnet Masks", "Subnet Mask", "(Optional) A list of associated Subnet Masks.")}
                    {strInterface("technical.pops", "POPs", "POP", "(Optional) A list of associated Points of Presence (POPs).")}
                     */}
                    <div className="row">
                        <div className="col">
                            <FormField
                                type="checkbox"
                                name="technical.dhcp"
                                label="DHCP?"
                                value={customer.technical.dhcp}
                                description="If checked, indicates the customer has DHCP enabled. If unchecked, indicates IPs are static and enables IP Table below."
                                handleChange={handleChange}
                            />
                        </div>
                    </div>
                    {!customer.technical.dhcp && !props.hideIPTable ?
                    <div className="row">
                        <div className="col">
                            {/** IP Table */}
                            <div className="form-field">
                                <label className="form-label">IP Table:</label>
                                <IPListTable claims={ipClaims} setClaims={setIpClaims} claimantObjectType="CustomerLocation" isEditing disabled={isSaving}/>
                            </div>
                        </div>
                    </div>
                    :null}
                    <div className="row">
                        <div className="col">
                            <FormField
                                type="textarea"
                                name="technical.equipmentLocation"
                                label="Equipment Location"
                                value={chimera.getAttr(customer, 'technical.equipmentLocation')}
                                description="A description of the equipment location. Be as descriptive as necessary."
                                handleChange={handleChange}
                                onBlur={trimOnBlur}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <FormField
                                type="textarea"
                                name="technical.servicePort"
                                label="Service Port"
                                value={chimera.getAttr(customer, 'technical.servicePort')}
                                description="The service port."
                                handleChange={handleChange}
                                onBlur={trimOnBlur}
                            />
                        </div>
                    </div>
                    </>
                :null}
                <div className="row justify-content-center">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                        <div className="form-field">
                            <div className="form-label">Technical POCs:</div>
                            {customer.technical.pocs.map((poc, i) => 
                                <div key={i} className={i !== customer.technical.pocs.length - 1 ? "section-outline mb-4" : "section-outline"}>
                                    <PocDisplay poc={poc} basePath={`technical.pocs[${i}]`} baseValue={customer} onChange={handleChange} disabled={isSaving} isEditing handleDelete={(event) => {event.preventDefault(); deletePoc("technical.pocs", i)}} series/>
                                </div>
                            )}
                            <button className="btn btn-success mt-2 mb-2" onClick={(event) => {event.preventDefault(); addPoc("technical.pocs")}}>
                                <i className="fas fa-plus"/>
                            </button>
                            <div className="form-text">
                                (Optional) Points of contact for technical purposes.
                            </div>
                        </div>
                    </div>
                </div>
                </>
            :null}
            
            <div className="row">
                <div className="col">
                    <button className="btn btn-primary" type="submit" disabled={isSaving || (!customer.serviceTypes.msp && !customer.serviceTypes.internet && !customer.serviceTypes.voip && !customer.serviceTypes.video)}>
                        <i className={isSaving ? "fas fa-spinner" : "fas fa-plus"}/>
                        &nbsp;{saveBtnLabel}
                    </button>
                </div>
            </div>
        </form>
    )
}

const NewCustomer = props => {
    return (
        <div className="container pb-5">
            <h1>Add Customer</h1>
            <BannerLog>
                <NewCustomerBody/>
            </BannerLog>
        </div>
    )
}

export { NewCustomer as default, NewCustomerBody };