import React, {useContext} from 'react';

import ModalContext from '../ModalContext';
import ExportModal from '../ExportModal';
import ButtonOptionList from '../ButtonOptionList';
import ModalCancelOnly from '../ModalCancelOnly';
import VoipOrderFormModal from './VoipOrderFormModal';
import AlertsContext from '../AlertsContext';
import Tooltip from '../Tooltip';
import ObjectTable from '../ObjectTable';
import ConvertTypeModal from './ConvertTypeModal';

const VoipOrderRow = props => {
    const alertsContext = useContext(AlertsContext);
    const statusColor = (status) => {
        switch(status) {
            case "New":
                return "info";
            case "In-Progress":
            case "Waiting on Customer":
                return "warning";
            case "Ready to Bill":
                return "primary";
            case "Completed":
                return "secondary"; //"success";
            case "Delay":
            case "Cancelled":
                return "danger";
            default:
                return "light";
        }
    }

    const typeColor = (type) => {
        switch(type) {
            case "New Customer":
                return "info";
            case "Modify Service":
                return "warning";
            case "Add Location":
                return "primary";
            case "Disconnect":
                return "danger";
            default:
                return "light";
        }
    }

    const abbreviateType = (type) => {
        switch(type) {
            case "New Customer":
                return "New";
            case "Modify Service":
                return "Mod.";
            case "Add Location":
                return "Order";
            case "Disconnect":
                return "Disco.";
            default:
                return "(None)";
        }
    }

    const renderFlag = () => {
        const reason = alertsContext.orderReason(props.object._id, 'voip');
        if(!reason) return null;
        else return <>&nbsp;<Tooltip text={reason}><i className="fas fa-flag text-danger"/></Tooltip></>
    }

    return (
        <>
            <td className={`cursor-pointer table-${typeColor(props.object.type)}`} onClick={(event) => {event.preventDefault(); props.clickedObject(props.object)}}>
                {abbreviateType(props.object.type)}
            </td>
            <td className={`cursor-pointer table-${statusColor(props.object.status)}`} onClick={(event) => {event.preventDefault(); props.clickedObject(props.object)}}>
                {props.object.status}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); props.clickedObject(props.object)}}>
                {props.object.customerName}{renderFlag()}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); props.clickedObject(props.object)}}>
                {props.object.onboardingPerson.email ? `${props.object.onboardingPerson.first.substring(0,1)}${props.object.onboardingPerson.last.substring(0,1)}` : '(None)'}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); props.clickedObject(props.object)}}>
                {props.object.salesperson ? props.object.salesperson : '(None)'}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); props.clickedObject(props.object)}}>
                {new Date(props.object.updatedAt).toLocaleString()}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); props.clickedObject(props.object)}}>
                {new Date(props.object.createdAt).toLocaleString()}
            </td>
        </>
    )
}

/**
 * 
 * @param {Array} props.orders The list of orders to display
 * @param {Function} props.clickedOrder A function that takes the clicked order as an argument
 */
const VoipOrdersTable = props => {
    const modaling = useContext(ModalContext);
    const alerts = useContext(AlertsContext);

    /** @param type An enum string associated with the `type` enum in the VoipOrder schema */
    const openNewOrderForm = (type) => {
        modaling.setModal(null);
        modaling.setModal(<VoipOrderFormModal type={type} modalContext={modaling} onClose={() => {props.setOrders(null); alerts.reload();}}/>)
    }

    const openNewOrderOptions = (_) => {
        const modal = <ModalCancelOnly context={modaling}>
            <ButtonOptionList options={[
                {
                    text: "New Customer",
                    icon: "fas fa-plus",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('New Customer');
                    }
                }
            ]}/>
            <hr/>
            <div className="row mb-3 mt-3">
                <h4>
                    For existing customers:
                </h4>
            </div>
            <ButtonOptionList options={[
                {
                    text: "Modify",
                    icon: "fas fa-screwdriver-wrench",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('Modify Service');
                    }
                },
                {
                    text: "Disconnect",
                    icon: "fas fa-minus",
                    func: (e) => {
                        e.preventDefault();
                        openNewOrderForm('Disconnect');
                    }
                }
            ]}/>
        </ModalCancelOnly>
        modaling.setModal(modal);
    }

    /** TODO: Implement exporting for VoipOrders */
    const openExportModal = (selectedObjects) => {
        modaling.setModal(<ExportModal objects={selectedObjects} model="voipOrder"/>);
    }

    const openConvertModal = (selectedObjects) => {
        const callback = () => {
            props.setOrders(null);
            modaling.setModal(null);
        }
        modaling.setModal(<ConvertTypeModal orderClass="voip" orders={selectedObjects} modalContext={modaling} bannerContext={props.parentBanners} callback={callback}/>);
    }

    return (
        <ObjectTable 
            id="voipOrdersTable"
            cols={[
                {
                    label: 'Type', 
                    sort: (ascending) => {
                        return (a, b) => {
                            const values = {
                                'New Customer': 0,
                                'Add Location': 1,
                                'Modify Service': 2,
                                'Disconnect': 3,
                            }
                            return values[`${a.status}`] < values[`${b.status}`] ? (ascending ? -1 : 1) : (ascending ? 1 : -1);
                        }
                    }
                },
                {
                    label: 'Status',
                    sort: (ascending) => {
                        return (a, b) => {
                            const values = {
                                'New': 0,
                                'In-Progress': 1,
                                'Ready to Bill': 2,
                                'Delay': 3,
                                'Waiting on Customer': 4,
                                'Completed': 5,
                                'Cancelled': 6
                            }
                            return values[`${a.status}`] < values[`${b.status}`] ? (ascending ? -1 : 1) : (ascending ? 1 : -1);
                        }
                    }
                },
                {
                    label: 'Customer Name',
                    sort: (ascending) => {
                        return (a, b) => a.customerName < b.customerName ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'Onboarding Person',
                    sort: (ascending) => {
                        return (a, b) => a.onboardingPerson.email < b.onboardingPerson.email ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'Salesperson',
                    sort: (ascending) => {
                        return (a, b) => a.salesperson < b.salesperson ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'Last Updated',
                    sort: (ascending) => {
                        return (a, b) => a.updatedAt < b.updatedAt ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'Created Date',
                    sort: (ascending) => {
                        return (a, b) => a.createdAt < b.createdAt ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                }
            ]}
            objects={props.orders}
            actions={[
                {label: 'Export', func: openExportModal},
                {label: 'Convert Type...', func: openConvertModal}
            ]}
            filters={[
                {label: 'Default', value: 'Default', func: (order) => order.status !== "Completed" && order.status !== "Cancelled"},
                {label: 'Show All', value: 'All', func: (_) => true},
                {label: 'New', value: 'New', func: (order) => order.status === "New"},
                {label: 'In-Progress', value: 'In-Progress', func: (order) => order.status === "In-Progress"},
                {label: 'Ready to Bill', value: 'Ready to Bill', func: (order) => order.status === "Ready to Bill"},
                {label: 'Completed', value: 'Completed', func: (order) => order.status === "Completed"},
                {label: 'Cancelled', value: 'Cancelled', func: (order) => order.status === "Cancelled"},
            ]}
            btns={[
                {label: 'VoIP Order', func: openNewOrderOptions}
            ]}
            rowElement={VoipOrderRow}
            clickedObject={props.clickedOrder}
            search
            paginated
            defaultSortByColName="Created Date"
            defaultSortAscending={false}
        />
    )
}

export default VoipOrdersTable