import React, {useState, useEffect, useContext} from 'react';

import chimera from '../../chimera';
import BannerContext from "../BannerLogContext";

const UnityDevicesCounter = props => {
    const [count, setCount] = useState({});
    const [loading, setLoading] = useState(true);
    const banners = useContext(BannerContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        /**
         * 1) Get all Users in the domain
         * 2) Organize by Site
         * 3) Get active phones
         * 4) Consider extensions with suffixes the same if the number matches
         */

        chimera.callAPI(signal, `/api/unity/domains/${props.domain}/subscribers`)
        .then(async subs => {
            const countBySite = {
                'Unassigned': 0
            }

            for(const sub of subs) {
                if(sub.site && countBySite[sub.site] === undefined) {
                    countBySite[sub.site] = 0;
                }
            }

            try {
                const devices = await chimera.callAPI(signal, `/api/unity/domains/${props.domain}/devices`);

                let countedExts = [];
                const activeDevices = devices.filter(device => device.registration_time) // blank value indicates inactivity
                for(const device of activeDevices) {
                    // extension is found in `contact` between 'sip:' and '@' -- we use `contact` instead of `aor` because `aor` pattern can vary
                    const ext = device.contact.substring(4, device.contact.indexOf('@')).replace(/\D/g, '');
                    if(countedExts.includes(ext)) {
                        continue;
                    }
                    
                    countedExts.push(ext);
                    const matchingSub = subs.find(sub => sub.user === device.subscriber_name);
                    if(matchingSub) {
                        if(matchingSub.site === "") {
                            countBySite['Unassigned'] += 1;
                        }
                        else {
                            countBySite[matchingSub.site] += 1;
                        }
                    }
                }

                setCount(countBySite);
                setLoading(false);
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('danger', 'Failed to get Unity device count', 'Error');
                }
            }
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', 'Failed to get Unity device count', 'Error');
            }
        })

        return () => {
            controller.abort();
        }
    }, []);

    const calculateTotal = () => {
        let total = 0;
        for(const key in count) {
            total += count[key];
        }
        return total;
    }

    return(
        <>
        {loading ?
            <i className="fas fa-spinner"/>
        :
            <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
            {Object.keys(count).filter(key => key !== 'Unassigned').map((site, i) => 
                <li key={i}><u>{site}:</u>&nbsp;{count[site]}</li>
            )}
            {count['Unassigned'] > 0 ? <li><u>Unassigned:</u>&nbsp;{count['Unassigned']}</li> : null}
            <li><u>Grand Total:</u>&nbsp;{calculateTotal()}</li>
            </ul>
        }
        </>
    )
}

export default UnityDevicesCounter;