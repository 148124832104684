import React, {useState, useEffect, useContext} from 'react';
import ModalContext from '../ModalContext';
import chimera from '../../chimera';
import ProductOrdersTable from './ProductOrdersTable';
import ProductOrderFormModal from './ProductOrderFormModal';
import BannerContext from '../BannerLogContext';
import { useParams, useNavigate } from 'react-router-dom';
import AlertsContext from '../AlertsContext';
import UserContext from '../../UserContext';

function userHasLimitedPerms(permissions) {
    if(!permissions) return false;
    return !permissions.managerial.write && !permissions.financial.write && !permissions.admin.write;
}

const ProductsTab = props => {
    const [orders, setOrders] = useState(null);
    const [openOrderId, setOpenOrderId] = useState(null);
    const modaling = useContext(ModalContext);
    const banners = useContext(BannerContext);
    const alerts = useContext(AlertsContext);
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if(id) {
            setOpenOrderId(id);
        }
    }, [id]);

    useEffect(() => {
        if(openOrderId) {
            modaling.setModal(<ProductOrderFormModal orderId={openOrderId} modalContext={modaling} banners={banners} onClose={onModalClose} isEstimate={userHasLimitedPerms(userContext.permissions)}/>);
        }
    }, [openOrderId]);

    useEffect(() => {
        if(orders === null) {
            chimera.callAPI(undefined, '/api/productorders')
            .then(newOrders => setOrders(newOrders))
            .catch(err => {
                console.error(err);
                if(props.bannerContext) {
                    props.bannerContext.addBanner('danger', 'Failed to read Product Orders; they cannot be displayed.', 'Error');
                }
            })
        }
    }, [orders, props.bannerContext])

    const onModalClose = () => {
        setOpenOrderId(null);
        setOrders(null);
        modaling.setModal(null);
        alerts.reload();
        navigate('/orders/products');
    }

    return (<div>
        <div className="row">
            <div className="col">
                <ProductOrdersTable orders={orders} setOrders={setOrders} clickedOrder={(order) => navigate(`/orders/products/${order._id}`)} parentBanners={banners}/>
            </div>
        </div>
    </div>)
}

export default ProductsTab;