import React, {useState} from "react";

import Modal, {choiceCancel} from '../Modal';
import FormFieldMicro from "../FormFieldMicro";
import chimera from "../../chimera";

const CreateSyncroTicketModal = props => {
    const [text, setText] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    const handleChange = (e) => {
        if(e.target.name === 'text') {
            setText(e.target.value);
        }
    }

    const choices = [
        choiceCancel(props)
    ]

    const run = async() => {
        setIsSaving(true);
        
        chimera.callAPI(undefined, `/api/${props.orderClass}orders/ticket`, 'POST', {id: props.orderId, text: text})
        .then(_ => {
            props.bannerContext.addBanner('info', 'Syncro Ticket created successfully', 'Success');
            props.callback();
        })
        .catch(err => {
            console.error(err);
            props.bannerContext.addBanner('danger', 'Failed to create Syncro Ticket', 'Error');
        })
        .finally(() => {
            props.modalContext.backtrack();
        })
    }

    const runButton = () => {
        return <button className="btn btn-primary" onClick={(e) => {e.preventDefault(); run()}} disabled={isSaving}>
            <i className={isSaving ? 'fas fa-spinner' : 'fas fa-arrow-right'}/>&nbsp;{isSaving ? 'Creating Ticket...' : 'Create Ticket'}
        </button>
    }

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Create Syncro Ticket</h3>
            <FormFieldMicro
                type="textarea"
                name="text"
                label="(Optional) Instruction Text"
                value={text}
                handleChange={handleChange}
                disabled={isSaving}
                fullWidth
                resize="vertical"
            />
            {runButton()}
        </Modal>
    );
}

export default CreateSyncroTicketModal;