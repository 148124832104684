import React, {useState, useEffect, useContext} from 'react';
import BannerContext, { BannerLog } from './BannerLogContext';
import chimera from '../chimera';
import LoadingSpinner from './LoadingSpinner';
import ModalContext from './ModalContext';
import ModalCancelOnly from './ModalCancelOnly';

const ProblemsDisplay = ({problems}) => {
    const modaling = useContext(ModalContext);

    const renderMore = (more) => {
        if(typeof more === "object") {
            return JSON.stringify(more, null, 4);
        }
        else {
            return more.toString();
        }
    }

    const openProblemsModal = (event) => {
        event.preventDefault();
        const modal = <ModalCancelOnly context={modaling}>
            <h3>Problems</h3>
            <table className="table table-hover table-bordered">
                <thead>
                    <tr>
                        <th>Problem</th>
                        <th>More</th>
                    </tr>
                </thead>
                <tbody>
                    {problems.map((problem, i) => <tr key={i}>
                        <td>{problem.text}</td>
                        <td>
                            {problem.more ? 
                            <div>
                                <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target={`#problemsMore${i}`} aria-expanded="false" aria-controls={`#problemsMore${i}`}>
                                    Show More
                                </button>
                                <div className="collapse" id={`problemsMore${i}`}>
                                    <div className="card card-body">
                                        {renderMore(problem.more)}
                                    </div>
                                </div>
                            </div>
                            : "(No more details)"}
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </ModalCancelOnly> 
        modaling.setModal(modal);
    }

    return(
        <button className={`btn btn-sm btn-${problems.length === 0 ? 'success' : 'warning'}`} onClick={openProblemsModal}>
            {problems.length}
        </button>
    )
}

const ChimeraBackupsMonitorBody = props => {
    const [monitors, setMonitors] = useState(null);
    const banners = useContext(BannerContext);

    useEffect(() => {
        if(monitors === null) {
            chimera.callAPI(undefined, '/api/chimerabackupmonitors')
            .then(newMonitors => setMonitors(newMonitors))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read monitors; table cannot be displayed', 'Error');
            })
        }
    }, [monitors]);

    return(
    <>
    {monitors ?
        <> 
        <h3>Last 30 Days</h3>
        <table className="table table-hover table-bordered">
            <thead>
                <tr>
                    <th>Problems</th>
                    <th>Size</th>
                    <th># of Objects</th>
                    <th>Uploaded At</th>
                    <th>Scanned At</th>
                </tr>
            </thead>
            <tbody>
                {monitors.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1).map((m, i) => <tr key={i}>
                    <td><ProblemsDisplay problems={m.problems}/></td>
                    <td>{m.totalSize} ({chimera.formatBytes(m.totalSize)})</td>
                    <td>{m.nFiles}</td>
                    <td>{(new Date(m.uploadTimestamp)).toLocaleString()}</td>
                    <td>{(new Date(m.createdAt)).toLocaleString()}</td>
                </tr>)}
            </tbody>
        </table>
        </>
    :
        <LoadingSpinner size={75}/>
    }
    </>
    )
}

const ChimeraBackupsMonitor = props => {
    return (
        <div className="container pb-5">
            <h1>
                Chimera Backups
            </h1>
            <BannerLog>
                <ChimeraBackupsMonitorBody/>
            </BannerLog>
        </div>
    )
}

export default ChimeraBackupsMonitor